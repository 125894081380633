import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import uploadMultipleImages from "../../../Utils/uploadMultipleImages";
import useGetLocationName from "../../../Hooks/useGetLocationName";
import Loading from "../../Shared/Loading";

const EditItem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [itemImgFiles, setItemImgFiles] = useState([]);
  const itemDetails = useLocation().state;

  const { id } = useParams();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const itemLocation = itemDetails?.location?.coordinates;
  const itemLocationName = useGetLocationName(
    itemLocation?.[1],
    itemLocation?.[0]
  );

  //Get Image URL
  // useEffect(() => {
  //   if (!itemImgFiles?.length) {
  //     setItemImgFiles([]);
  //     return;
  //   }
  //   itemImgFiles?.map((itemImgFile) => {
  //     const itemImgURLfile = URL.createObjectURL(itemImgFile);
  //     setItemImgURL(itemImgURLfile);
  //     return () => {
  //       return URL.revokeObjectURL(itemImgURLfile);
  //     };
  //   });
  // }, [itemImgFiles]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const itemImagePathname = `vip-ware/items/${itemDetails?.category?.name}/${data?.itemName}`;
    let itemImageURLs;
    if (itemImgFiles?.length > 0) {
      itemImageURLs = await uploadMultipleImages(
        itemImgFiles,
        itemImagePathname
      );
    }

    const itemInfo = {
      itemName: data?.itemName || itemDetails?.itemName,
      categoryID: itemDetails?.category.id._id,
      categoryName: itemDetails?.category.id.name,
      brand: data?.brand || itemDetails?.brand,
      description: data?.description || itemDetails?.description,
      // imageGallery: itemImageURLs || itemDetails?.imageGallery,
      imageGallery: itemImageURLs
        ? [...itemDetails?.imageGallery, ...itemImageURLs]
        : [...itemDetails?.imageGallery],
      price: parseInt(data?.price) || itemDetails?.price,
      discPrice: parseInt(data?.price) || itemDetails?.price,
      // lat: lat,
      // lng: lng,
      lat: lat || itemDetails?.location.coordinates[1],
      lng: lng || itemDetails?.location.coordinates[0],
    };

    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    await axios
      .put(`${process.env.REACT_APP_ENDPOINT}/item/${id}`, itemInfo, config)
      .then((data) => {
        if (data.status === 200) {
          toast.success(`Item is edited Successfully`, {
            theme: "colored",
          });
          // navigate(`/items/category/${itemDetails?.category?.name}`);
          navigate(-1);
          localStorage.setItem(
            "category",
            JSON.stringify(itemDetails?.category)
          );
        } else {
          toast.error(`Can't Edit Item`, { theme: "colored" });
        }
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-6xl mx-auto text-black dark:text-[#E2E2E2]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        Home / Sell Item /
        <span className="text-black dark:text-[#E2E2E2]"> Edit Details</span>
      </p>
      <div className="bg-white dark:bg-secondary rounded-[20px] p-6 sm:p-12">
        <p className="font-semibold mb-3">Details</p>
        <hr />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-2 sm:space-y-5 mt-8"
        >
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-5">
            <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
              <img
                className="mask mask-circle object-cover w-[34px] h-[34px]"
                src={itemDetails?.category.id.logo}
                alt=" "
              />
              <p className="text-sm">{itemDetails?.category?.id.name}</p>
              {/* <label
                htmlFor="select-category-modal"
                className="cursor-pointer bg-[#161616] h-6 w-6 absolute rounded flex justify-center items-center bottom-[35px] left-[130px]"
              >
                <Edit />
              </label> */}
            </div>
            {/* {categoryDetails?.subcategory && (
              <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
                <img
                  className="mask mask-circle object-cover w-[34px] h-[34px]"
                  src={categoryDetails?.subcategory?.logo}
                  alt=" "
                />
                <p className="text-sm">{categoryDetails?.subcategory?.name}</p>
              
              </div>
            )} */}
          </div>
          <div className="form-control max-w-sm">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Price *
              </span>
            </label>
            <input
              type="number"
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={"000"}
              defaultValue={itemDetails && itemDetails?.price}
              {...register("price", {
                required: {
                  value: true,
                  message: "Price is required",
                },
              })}
            />
            <label className="label">
              {errors.price?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.price.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control max-w-lg">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Name of the Product *
              </span>
            </label>
            <input
              type="text"
              placeholder={"Product Name"}
              defaultValue={itemDetails && itemDetails?.itemName}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("itemName", {
                required: {
                  value: true,
                  message: "Item Name is required",
                },
              })}
            />
            <label className="label">
              {errors.itemName?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.itemName.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Description *
              </span>
              <span className="label-text-alt">{250}</span>
            </label>
            <textarea
              type="text"
              placeholder="Description"
              defaultValue={itemDetails && itemDetails?.description}
              className="textarea textarea-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none h-24"
              {...register("description", {
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            <label className="label">
              {errors.description?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.description.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full my-6">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Address *
              </span>
            </label>
            <Autocomplete
              apiKey={googleMapsApiKey}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={"Your Address"}
              defaultValue={
                itemDetails &&
                `${itemLocationName?.city}, ${itemLocationName?.state}`
              }
              onPlaceSelected={(place) => {
                const lat = place?.geometry?.location?.lat();
                const lng = place?.geometry?.location?.lng();
                setLat(lat);
                setLng(lng);
              }}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  City *
                </span>
              </label>
              <input
                type="text"
                placeholder={"City"}
                defaultValue={itemLocationName && itemLocationName?.city}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("city", {
                  required: {
                    value: true,
                    message: "City is required",
                  },
                })}
              />
              <label className="label">
                {errors.city?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.city.message}
                  </span>
                )}
              </label>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  State *
                </span>
              </label>
              <input
                type="text"
                placeholder="State"
                defaultValue={itemLocationName && itemLocationName?.state}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("state", {
                  required: {
                    value: true,
                    message: "State is required",
                  },
                })}
              />
              <label className="label">
                {errors.state?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.state.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div>
            <label className="label">
              <span className="label-text dark:text-white font-semibold">
                Upload photos upto 12 *
              </span>
            </label>
            <div className="flex flex-wrap gap-3">
              {itemDetails?.imageGallery &&
                itemDetails?.imageGallery?.map((img, index) => (
                  <img
                    key={index}
                    src={process.env.REACT_APP_BACKEND_URL + '/' + img}
                    // src={img}
                    alt=" "
                    className="w-[100px] h-[75px] rounded-[10px] object-cover"
                  />
                ))}
              {itemImgFiles &&
                [...itemImgFiles]?.map((itemImgFile, index) => {
                  return (
                    <img
                      key={index}
                      src={URL.createObjectURL(itemImgFile)}
                      alt=" "
                      className="w-[100px] h-[75px] rounded-[10px] object-cover"
                    />
                  );
                })}
              {itemDetails?.imageGallery.length + itemImgFiles.length < 12 && (
                <label className="cursor-pointer bg-[#A6A6A6] w-[100px] h-[75px] rounded-[10px] flex flex-col justify-center items-center gap-1">
                  <MdOutlineAddPhotoAlternate className="text-black h-7 w-7" />
                  <p className="font-[roboto] text-xs text-black">Add Photo</p>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setItemImgFiles([...itemImgFiles, ...e.target.files]);
                    }}
                  />
                </label>
              )}
            </div>
          </div>
          <hr className="my-8" />
          <div className="grid grid-cols-2 gap-3">
            <input
              type="button"
              onClick={() => navigate(-1)}
              className="btn w-full bg-transparent border-black dark:border-white text-black dark:text-white hover:bg-transparent normal-case"
              value="Cancel"
            />
            <input
              type="submit"
              className="btn w-full bg-black dark:bg-white text-white dark:text-black normal-case"
              value="Update"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditItem;
