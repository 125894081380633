import React, { useEffect, useState } from "react";
import MyModal from "./MyModal";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const AddAddressModal = ({ isOpen, setIsOpen }) => {
  const {t} = useTranslation();
  const [indianStatesData, setIndianStatesData] = useState([]);
  useEffect(() => {
    fetch("jsons/states.json")
      .then((res) => res.json())
      .then((data) => setIndianStatesData(data));
  }, []);

  const queryClient = useQueryClient();

  const addAddressMutation = useMutation(
    async (newAddress) => {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/addAddress/${localStorage.getItem("user-id")}`,
        newAddress
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userProfile", localStorage.getItem("user-id")]);
        setIsOpen(false);
        toast.success("Address added successfully!");
      },
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    addAddressMutation.mutate(data);
  };
  
  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);


  return (
    <MyModal isOpen={isOpen} setIsOpen={setIsOpen} title={t("Add New Address")}>
      <form  onSubmit={handleSubmit(onSubmit)} className="space-y-3">
        <input
          className="input input-bordered w-full bg-white dark:bg-black"
          type="text"
          placeholder={t("Name of the Person to Pick the Delivery")}
          id="deliveryName"
          autoComplete="off"
          {...register("deliveryName")}
          required
        />
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="number"
            placeholder={t("Delivery Mobile Number")}
            autoComplete="off"
            id="deliveryNumber"
            {...register("deliveryNumber", {
              required: "This field is required",
              pattern: {
                value: /^\d{10}$/, // Regex pattern for 10-digit number
                message: t("Please enter a valid 10-digit number"),
              },
            })}
          />
          {errors.deliveryNumber && (
            <span className="text-red-500">
              {errors.deliveryNumber.message}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("House No, Flat No")}
            id="houseNumber"
            autoComplete="off"
            {...register("houseNumber")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("Road Name, Area, Village")}
            id="roadName"
            autoComplete="off"
            {...register("roadName")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("City/Town")}
            id="cityName"
            autoComplete="off"
            {...register("cityName")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("District")}
            id="districtName"
            autoComplete="off"
            {...register("districtName")}
            required
          />
        </div>
        <div className="flex flex-col gap-3 my-4">
          <select
            className="input input-bordered w-full bg-white dark:bg-black"
            id="stateName"
            autoComplete="off"
            {...register("stateName")}
            required
            defaultValue="" // Set default value to an empty string
          >
            <option value="" disabled>
              {t('Select State')}
            </option>
            {indianStatesData.map((state) => (
              <option key={state?.code}>{state?.name}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="number"
            placeholder={t('Enter Pincode')}
            id="pinCode"
            autoComplete="off"
            {...register("pinCode")}
            required
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="btn btn-info bg-blue-200 text-black btn-sm"
            onClick={() => reset()}
          >
            {t('Reset')}
          </button>
          <button
            type="submit"
            className="btn btn-warning bg-orange-500 text-white btn-sm"
            disabled={addAddressMutation.isLoading}
          >
            {addAddressMutation.isLoading ? t("Saving...") : t("Save Address")}
          </button>
        </div>
      </form>
    </MyModal>
  );
};

export default AddAddressModal;
