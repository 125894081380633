import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from '../../Shared/Loading'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AllArticles = () => {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: '' })
    const [loading, setLoading] = useState(false)
    const [articles, setArticles] = useState([])

    const url = `${process.env.REACT_APP_BACKEND_URL}/articles?lang=en`;

    const HandleDelete = async (id) => {
        try {
            setLoading(true);
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/articles/${id}`);
            if (res.status === 200) {
                toast.success('Article deleted successfully');
                setDeleteModal({ show: false, id: '' });
                fetchArticles();
            }
        } catch (err) {
            console.log(err);
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchArticles = async () => {
        try {
            setLoading(true);
            const res = await axios.get(url);
            setArticles(res.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            toast.error(err.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchArticles();
    }, [])

    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dataToRender = articles?.slice(startIndex, endIndex);

    if (loading) {
        return <Loading />
    }

    return (
        <div className='p-10'>
            <div className='flex justify-between w-full'>
                <h1 className='font-bold text-3xl'>Articles : {articles?.length}</h1>
                <button className='btn btn-warning bg-orange-500 text-white' onClick={() => navigate('addArticle')}>Add Article</button>
            </div>
            <div className="overflow-x-auto mt-8 pr-4">
                <table className='table w-full mt-5'>
                    <thead>
                        <tr className='text-center dark:bg-secondary'>
                            <th className='dark:bg-secondary'>#</th>
                            <th className='dark:bg-secondary'>Image</th>
                            <th className='dark:bg-secondary'>Title</th>
                            <th className='dark:bg-secondary'>Description</th>
                            <th className='dark:bg-secondary'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataToRender?.map((article, index) => (
                            <tr key={startIndex + index + 1} className='text-center'>
                                <td className='dark:bg-secondary'>{startIndex + index + 1}</td>
                                <td className='dark:bg-secondary w-[30%]'><img src={process.env.REACT_APP_BACKEND_URL + '/' + article.imageMain} alt={article.title} /></td>
                                <td className='dark:bg-secondary'>{article.title}</td>
                                <td className='dark:bg-secondary'>{moment(article.createdAt).format('MMMM Do YYYY')}</td>
                                <td className='dark:bg-secondary'>
                                    <div className='flex justify-evenly'>
                                        <button className='btn btn-warning bg-green-500 text-white' onClick={() => navigate(`editArticle/${article._id}`)}>Edit</button>
                                        <button className='btn btn-warning bg-red-500 text-white' onClick={() => setDeleteModal({ show: true, id: article._id })}>Delete</button>
                                        {deleteModal && deleteModal.show && deleteModal.id === article._id &&
                                            <div className='fixed w-full z-50 h-full top-0 left-0 flex justify-center items-center backdrop-blur-sm'>
                                                <div className='flex flex-col bg-slate-800 p-5 rounded-md'>
                                                    <h1 className='font-bold text-3xl'>Are you sure you want to delete this?</h1>
                                                    <div className='flex justify-end gap-5 mt-5'>
                                                        <button
                                                            className='btn btn-warning bg-red-500 text-white'
                                                            onClick={() => setDeleteModal({ show: false, id: '' })}
                                                            disabled={loading}
                                                        >No</button>
                                                        <button
                                                            className='btn btn-success bg-green-500 text-white'
                                                            onClick={() => HandleDelete(deleteModal.id)}
                                                            disabled={loading}
                                                        >Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                < div className='flex justify-end items-center gap-1 mt-5' >
                    <p className='join-item text-black dark:text-white'>Showing {startIndex + 1} - {endIndex} of {articles?.length} articles</p>
                    <p className='join-item text-black dark:text-white'>on page {currentPage} of {Math.ceil(articles?.length / itemsPerPage)}</p>
                </div>
                <div className='flex justify-end gap-5 mt-5'>
                    <button
                        className='btn btn-warning bg-orange-500 text-white'
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >Previous</button>
                    <button
                        className='btn btn-warning bg-green-500 text-white'
                        onClick={() => setCurrentPage((prev) => (prev + 1))}
                        disabled={dataToRender?.length < itemsPerPage}
                    >Next</button>
                </div>
            </div>
        </div>
    )
}

export default AllArticles