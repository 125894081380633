// import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Contact Us</title>
      </Helmet>
      <div className="p-8 bg-orange-500">
        <h1 className="text-center font-semibold text-3xl text-white">
          {t("Contact Us")}
        </h1>
      </div>
      <div className="pt-8 text-center space-y-4">
        <h1 className=" text-2xl font-semibold">
          {t("For Further Queries Please Contact")}
        </h1>
        <p className="text-orange-500 font-bold text-xl">
          {t("Mobile Number")} : 9535601936
        </p>
        {/* <p>
          Write to us at :{" "}
          <span className="text-orange-500">sridiyastorestn@gmail.com</span>
        </p>
        <p className="font-bold text-orange-500">
          1. Andhra & Telangana : 93460 16998
        </p>
        <p className="font-bold text-orange-500">2. Tamil Nadu : 95972 15816</p>
        <p className="font-bold text-orange-500">3. Karnataka : 80566 01121</p>
        <p className="font-bold text-orange-500">4. Hindi: 75682 24311</p>
        <p className="font-semibold text-2xl">Join Us</p>
        <div className="flex justify-center items-center gap-3 mt-5">
          <button className="btn btn-circle border-none hover:bg-white shadow-md bg-slate-200">
            <FaWhatsapp className="text-green-700 text-2xl"></FaWhatsapp>
          </button>
          <a
            href={facebookPageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-circle border-none hover:bg-white shadow-md bg-slate-200"
          >
            <FaFacebook className="text-blue-600 text-2xl" />
          </a>
          <button className="btn btn-circle border-none hover:bg-white shadow-md bg-slate-200">
            <FaYoutube className="text-red-700 text-2xl"></FaYoutube>
          </button>
          <button className="btn btn-circle border-none hover:bg-white shadow-md bg-slate-200">
            <FaInstagram className="text-red-700 text-2xl"></FaInstagram>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
