import React, { useState } from "react";
import i18next from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
// import useGetLocationName from "../../Hooks/useGetLocationName";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { MdArticle, MdOutlineArticle, MdOutlineDashboardCustomize, MdOutlineLogin } from "react-icons/md";
import { FaCartPlus, FaSearch } from "react-icons/fa";
import { BsGlobe2, BsInfoCircle } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { RiRefund2Line } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import {
  MdOutlinePrivacyTip,
  MdOutlineLocalShipping,
  MdContactSupport,
} from "react-icons/md";
import SearchModal from "../Modals/SearchModal";
import logo1 from "../../Assets/logo/Bharat.jpg";
import logo2 from "../../Assets/logo/Bharat.png";
import { auth } from "../../firebase.init";
import { AiOutlineShopping } from "react-icons/ai";
import useCart from "../../Hooks/useCart";
import { useTranslation } from "react-i18next";
// import { useAuthState } from "react-firebase-hooks/auth";
import useAdmin from "../../Hooks/useAdmin";

const Navbar = ({ toggleTheme, searchQuery, theme, setSearchQuery }) => {
  const { t } = useTranslation();
  const [carts] = useCart();
  // const [user] = useAuthState(auth);
  const [isAdmin] = useAdmin();
  // console.log(isAdmin);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const activeBgColorClass = "bg-active";
  const activeTextColorClass = "text-active";
  // const [lat, setLat] = useState(0);
  // const [lng, setLng] = useState(0);
  // const [showLocationModal, setShowLocationModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  // const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const userImage = localStorage.getItem("user-image");
  // // const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  // const pathname = useLocation().pathname;

  // showLocationModal
  //   ? (document.body.style.overflow = "hidden")
  //   : (document.body.style.overflow = "auto");

  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
    localStorage.setItem("lang", language);
  };

  const handleOnClose = () => {
    setShowModal(false);
  };

  const logout = async () => {
    try {
      await signOut(auth);
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
      localStorage.removeItem("firebase-user-id");
      localStorage.removeItem("favorites");
      localStorage.removeItem("user-image");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user-mobile");

      navigate("/signIn");
    } catch (error) {
      console.log("Logout error:", error);
    }
  };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  // if (
  //   window.location.reload &&
  //   window.location.pathname === "/" &&
  //   !localStorage.getItem("coordinates")
  // ) {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const lat = position.coords.latitude;
  //       const lng = position.coords.longitude;
  //       handleLocationLocalStorage(lat, lng);
  //       // console.log(lat, lng);
  //     });
  //   }
  // }

  // const handleLocationLocalStorage = (lat, lng) => {
  //   setLat(lat);
  //   setLng(lng);
  //   localStorage.setItem("coordinates", JSON.stringify({ lat, lng }));
  //   // refetch();
  // };

  // const latValue = lat || coordinates?.lat;
  // const lngValue = lng || coordinates?.lng;
  // const location = useGetLocationName(latValue, lngValue);

  return (
    <div className="sticky top-0 z-10 text-black ">
      <div className="navbar dark:bg-black dark:border-b bg-white shadow-sm h-full lg:h-20">
        <div>
          <Link
            to={"/"}
            className="w-32 md:w-36 rounded-md normal-case text-xl"
          >
            <img
              src={theme === "dark" ? logo2 : logo1}
              alt="logo"
              className="rounded-xl"
            />
          </Link>
        </div>

        <div className="navbar text-lg lg:flex">
          <ul className="menu hidden xl:block items-center  menu-horizontal px-1">
            <div className="flex items-center text-md gap-2">
              <li>
                <Link
                  to={"/"}
                  className={`${location.pathname === "/"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } w-32 dark:text-white md:w-28 rounded-md normal-case text-xl `}
                >
                  <AiOutlineShopping className="text-md"></AiOutlineShopping>
                  <p>{t("Shop")}</p>
                </Link>
              </li>

              <li>
                <Link
                  to={"/refundPolicy"}
                  className={`${location.pathname === "/refundPolicy"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } w-32 dark:text-white md:w-48 rounded-md normal-case text-xl `}
                >
                  <RiRefund2Line className="text-md"></RiRefund2Line>
                  <p>{t("Refund Policy")}</p>
                </Link>
              </li>

              <li>
                <Link
                  to={"/shipping"}
                  className={`${location.pathname === "/shipping"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } w-32 dark:text-white md:w-36 rounded-md normal-case text-xl `}
                >
                  <MdOutlinePrivacyTip className="text-md"></MdOutlinePrivacyTip>
                  {t("Shipping")}
                </Link>
              </li>

              {!isAdmin && (
                <li>
                  <Link
                    to={"/contactUs"}
                    className={`${location.pathname === "/contactUs"
                      ? activeBgColorClass + " " + activeTextColorClass
                      : ""
                      } w-32 dark:text-white md:w-44 rounded-md normal-case text-xl `}
                  >
                    <BsInfoCircle className="text-lg ml-2"></BsInfoCircle>
                    {t("Contact Us")}
                  </Link>
                </li>
              )}

              <li>
                <Link to={"/articles"}
                  className={`${location.pathname === "/articles" ? activeBgColorClass + " " + activeTextColorClass : ""} dark:text-white rounded-md normal-case text-xl `}>
                  <MdOutlineArticle className="text-2xl"></MdOutlineArticle>
                  {t("Articles")}
                </Link>
              </li>

              {isAdmin && (
                <li>
                  <Link
                    to={"/adminDashboard/allOrders"}
                    className={`${location.pathname === "/adminDashboard/allOrders"
                      ? activeBgColorClass + " " + activeTextColorClass
                      : ""
                      } w-32 dark:text-white md:w-44 rounded-md normal-case text-xl `}
                  >
                    <MdOutlineDashboardCustomize className="text-md"></MdOutlineDashboardCustomize>
                    {t("Dashboard")}
                  </Link>
                </li>
              )}

              {localStorage.getItem("user-id") && (
                <li>
                  <Link
                    to={"/orderDetails"}
                    className={`${location.pathname === "/orderDetails"
                      ? activeBgColorClass + " " + activeTextColorClass
                      : ""
                      } w-32 dark:text-white md:w-40 rounded-md normal-case text-xl `}
                  >
                    <TbTruckDelivery className="text-xl"></TbTruckDelivery>
                    {t("My Orders")}
                  </Link>
                </li>
              )}
            </div>
          </ul>
        </div>

        <div className="xs:gap-1 sm:mr-2">
          <button
            onClick={() => setShowModal(true)}
            className="bg-orange-500 xs:mr-2 sm:mr-3 mr-1 btn btn-sm h-9 w-10 btn-warning rounded-lg text-white"
          >
            <FaSearch className="xs:text-lg"></FaSearch>
          </button>
          <div className="indicator">
            <span className="indicator-item mr-3 w-5 text-center -mt-1 bg-red-600 text-white rounded-full">
              {carts?.length || 0}
            </span>
            <Link to={"/myCart"}>
              <button className="bg-orange-500 w-10 sm:mr-3 btn btn-sm h-9 btn-warning rounded-lg text-white">
                <FaCartPlus className="text-xl"></FaCartPlus>
              </button>
            </Link>
          </div>

          <Link className="cursor-pointer hidden xl:block " to={"/favourites"}>
            <button className="btn btn-circle btn-ghost xs:mr-2">
              <AiOutlineHeart className="text-3xl text-black dark:text-white"></AiOutlineHeart>
            </button>
          </Link>
          <div className="hidden xl:block mt-[6px]">
            <label className="swap swap-rotate">
              <input
                onChange={toggleTheme}
                type="checkbox"
                checked={
                  localStorage.getItem("theme") === "light" ? false : true
                }
              />

              <svg
                className="swap-on fill-current w-8 h-8 xs:mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
              </svg>

              <svg
                className="swap-off fill-current w-[28px] h-8  text-black"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
              </svg>
            </label>
          </div>
          <div className="dropdown dropdown-end hidden xl:block">
            <label tabIndex={0} className="m-1">
              <BsGlobe2 className="dark:text-white w-[26px] cursor-pointer h-7 ml-1 mr-2 text-black"></BsGlobe2>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu shadow px-2 py-1 bg-white dark:bg-secondary rounded-box w-40"
            >
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("bn")}
                >
                  বাংলা
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("hi")}
                >
                  हिंदी
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("mr")}
                >
                  मराठी
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("or")}
                >
                  ଓଡିଆ
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("gu")}
                >
                  ગુજરાતી
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("ta")}
                >
                  தமிழ்
                </p>
              </li>

              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("te")}
                >
                  తెలుగు
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("kn")}
                >
                  ಕನ್ನಡ
                </p>
              </li>
              <li>
                <p
                  className="text-black dark:text-white"
                  onClick={() => changeLanguage("ml")}
                >
                  മലയാളം
                </p>
              </li>
            </ul>
          </div>

          {localStorage.getItem("user-id") ? (
            <div className="dropdown dropdown-end">
              <label
                tabIndex={0}
                className="btn btn-ghost btn-circle bg-transparent hover:bg-transparent p-0 flex items-center"
              >
                <div className="border-white border-2  ml-1 rounded-full">
                  <img
                    src={
                      userImage
                        ? userImage
                        : "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                    }
                    alt="user"
                    className="rounded-full w-8 xl:w-[30px]"
                  />
                </div>
              </label>
              <ul
                tabIndex={0}
                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white dark:bg-secondary rounded-box w-52 "
              >
                <li>
                  <Link
                    className="text-xs sm:text-base text-black dark:text-white"
                    to={"/profile"}
                  >
                    <CgProfile /> {t("View Profile")}
                  </Link>
                </li>

                <li>
                  <p
                    className="text-xs sm:text-base text-black dark:text-white"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <MdOutlineLogin /> {t("Logout")}
                  </p>
                </li>
              </ul>
            </div>
          ) : (
            location.pathname !== "/signIn" && (
              <div className="flex justify-center items-center gap-4">
                <ul>
                  <li>
                    <Link to={"/signIn"}>
                      <button className="hidden btn-sm h-9 xl:block btn btnn-sm bg-orange-500 text-white btn-warning">
                        {t("Login")}
                      </button>
                    </Link>
                  </li>
                </ul>
                {/* <button
                onClick={() => {
                  navigate("/login");
                }}
                className="invisible lg:visible btn btn-sm btn-warning h-9 bg-orange-500 text-white font-bold flex justify-center gap-2 items-center font-[inter] text-md mx-auto"
              >
                <p>Sign In</p>
              </button> */}
              </div>
            )
          )}
        </div>

        <div className="xl:hidden hamburger cursor-pointer">
          <div
            onClick={() => {
              setOpenMenu(!openMenu);
              setSearchQuery("");
            }}
          >
            {openMenu ? (
              <ImCross className="ml-2 h-7 w-7 text-black dark:text-white  mx-auto" />
            ) : (
              <GiHamburgerMenu className="ml-2 h-7 w-7 text-black dark:text-white mx-auto" />
            )}
          </div>
          <div
            className={`bg-secondary text-white h-auto text-center rounded-b flex flex-col absolute duration-300 ease-in right-0 shadow-md space-y-2 w-[180px] ${openMenu ? "top-20" : "top-[-700px]"
              }`}
          >
            <ul className="menu menu-vertical pt-3 pb-6">
              <div className="flex justify-center items-center">
                <Link className="cursor-pointer" to={"/favourites"}>
                  <button
                    onClick={closeMenu}
                    className="btn btn-circle btn-ghost xs:mr-2"
                  >
                    <AiOutlineHeart className="text-3xl text-white"></AiOutlineHeart>
                  </button>
                </Link>
                <div className="mt-[6px] mr-[6px]">
                  <label className="swap swap-rotate">
                    <input
                      onChange={toggleTheme}
                      type="checkbox"
                      checked={
                        localStorage.getItem("theme") === "light" ? false : true
                      }
                    />

                    <svg
                      className="swap-on fill-current w-8 h-8 xs:mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
                    </svg>

                    <svg
                      className="swap-off fill-current w-[28px] h-8  text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
                    </svg>
                  </label>
                </div>
                <div className="dropdown dropdown-end">
                  <label tabIndex={0} className="m-1">
                    <BsGlobe2 className="text-white w-[26px] cursor-pointer h-7 ml-1 mr-2 "></BsGlobe2>
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content text-black z-[1] menu p-2 shadow bg-white dark:bg-black w-40"
                  >
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("en");
                          closeMenu();
                        }}
                      >
                        English
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("bn");
                          closeMenu();
                        }}
                      >
                        বাংলা
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("hi");
                          closeMenu();
                        }}
                      >
                        हिंदी
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("mr");
                          closeMenu();
                        }}
                      >
                        मराठी
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("or");
                          closeMenu();
                        }}
                      >
                        ଓଡିଆ
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("gu");
                          closeMenu();
                        }}
                      >
                        ગુજરાતી
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("ta");
                          closeMenu();
                        }}
                      >
                        தமிழ்
                      </p>
                    </li>

                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("te");
                          closeMenu();
                        }}
                      >
                        తెలుగు
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("kn");
                          closeMenu();
                        }}
                      >
                        ಕನ್ನಡ
                      </p>
                    </li>
                    <li>
                      <p
                        className="text-black dark:text-white"
                        onClick={() => {
                          changeLanguage("ml");
                          closeMenu();
                        }}
                      >
                        മലയാളം
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <li>
                  <Link>Horoscope</Link>
                </li>
                <li>
                  <Link>Panchang</Link>
                </li>
                <li>
                  <Link>Kundli</Link>
                </li>
                <li>
                  <Link>Browse Events</Link>
                </li>
                <li>
                  <Link>Create Events</Link>
                </li>
                <li>
                  <Link>Articles</Link>
                </li>
                <li>
                  <Link>Events</Link>
                </li>
                <li>
                  <Link>Matrimony</Link>
                </li> */}
              <li>
                <Link
                  to={"/"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <AiOutlineShopping className="text-md"></AiOutlineShopping>
                  {t("Shop")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/refundPolicy"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/refundPolicy"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <RiRefund2Line className="text-md"></RiRefund2Line>
                  {t("Refund Policy")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/privacyPolicy"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/privacyPolicy"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <MdOutlinePrivacyTip className="text-md"></MdOutlinePrivacyTip>
                  {t("Privacy Policy")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/shipping"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/shipping"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <MdOutlineLocalShipping className="text-md"></MdOutlineLocalShipping>
                  {t("Shipping")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/contactUs"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/contactUs"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <MdContactSupport className="text-lg"></MdContactSupport>
                  {t("Contact Us")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/articles"}
                  onClick={closeMenu}
                  className={`${location.pathname === "/articles"
                    ? activeBgColorClass + " " + activeTextColorClass
                    : ""
                    } `}
                >
                  <MdArticle className="text-2xl"></MdArticle>
                  {t("Articles")}
                </Link>
              </li>
              {localStorage.getItem("user-id") && (
                <li>
                  <Link
                    to={"/orderDetails"}
                    onClick={closeMenu}
                    className={`${location.pathname === "/orderDetails"
                      ? activeBgColorClass + " " + activeTextColorClass
                      : ""
                      } `}
                  >
                    <TbTruckDelivery className="text-xl"></TbTruckDelivery>
                    {t("My Orders")}
                  </Link>
                </li>
              )}
              {!localStorage.getItem("user-id") && (
                <li>
                  <Link to={"/signIn"} onClick={closeMenu}>
                    <button className="btn btn-sm btn-warning h-9 bg-orange-500 text-white font-bold flex justify-center gap-2 items-center font-[inter] text-md mx-auto">
                      <p>{t("Login")}</p>
                    </button>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <SearchModal onClose={handleOnClose} visible={showModal}></SearchModal>
      {/* <LocationModal
        showModal={showLocationModal}
        setShowModal={setShowLocationModal}
        handleLocationLocalStorage={handleLocationLocalStorage}
      /> */}
    </div>
  );
};

export default Navbar;
