import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { BsFacebook, BsMessenger, BsWhatsapp, BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";

const ShareItemModal = ({ showShareModal, setShowShareModal, name }) => {
  const [copied, setCopied] = useState(false);
  const postURL = window.location;

  return (
    <>
      {showShareModal ? (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm ">
          <div className="relative rounded-lg bg-white dark:bg-secondary w-64 xs:w-[380px] sm:w-[500px] bottom-16 p-8 space-y-6 shadow-md">
            <div className="flex justify-between">
              <p className="text-black dark:text-white font-[inter] font-semibold">
                Share {name}
              </p>
              <button
                className=" bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center"
                onClick={() => {
                  setShowShareModal(false);
                  setCopied(false);
                }}
              >
                <RxCross1 className="text-white dark:text-black" />
              </button>
            </div>
            <div className="grid grid-cols-5">
              <div className="bg-blue-100 rounded-full w-8 h-8 xs:w-14 xs:h-14 flex justify-center items-center mx-auto">
                <FacebookShareButton url={postURL}>
                  <BsFacebook className="text-blue-600 w-5 h-5 xs:w-8 xs:h-8" />
                </FacebookShareButton>
              </div>
              <div className="bg-indigo-100 rounded-full w-8 h-8 xs:w-14 xs:h-14 flex justify-center items-center mx-auto">
                <FacebookMessengerShareButton url={postURL}>
                  <BsMessenger className="text-indigo-600 w-5 h-5 xs:w-8 xs:h-8" />
                </FacebookMessengerShareButton>
              </div>
              <div className="bg-green-100 rounded-full w-8 h-8 xs:w-14 xs:h-14 flex justify-center items-center mx-auto">
                <WhatsappShareButton url={postURL}>
                  <BsWhatsapp className="text-green-500 w-5 h-5 xs:w-8 xs:h-8" />
                </WhatsappShareButton>
              </div>
              <div className="bg-sky-100 rounded-full w-8 h-8 xs:w-14 xs:h-14 flex justify-center items-center mx-auto">
                <TwitterShareButton url={postURL}>
                  <BsTwitter className="text-sky-400 w-5 h-5 xs:w-8 xs:h-8" />
                </TwitterShareButton>
              </div>
              <div className="bg-sky-100 rounded-full w-8 h-8 xs:w-14 xs:h-14 flex justify-center items-center mx-auto">
                <TelegramShareButton url={postURL}>
                  <FaTelegramPlane className="text-blue-500 w-5 h-5 xs:w-8 xs:h-8 pr-1" />
                </TelegramShareButton>
              </div>
            </div>
            <input
              type="text"
              readOnly
              value={postURL}
              className="input input-bordered w-full text-black text-sm font-semibold font-[poppins]"
            />
            <div className="flex items-center justify-end">
              {!copied ? (
                <CopyToClipboard
                  text={postURL}
                  onCopy={() => setCopied(true)}
                  className="bg-slate-800 text-white hover:bg-slate-900 font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg cursor-pointer"
                >
                  <span>Copy</span>
                </CopyToClipboard>
              ) : (
                <span className="bg-slate-800 text-white font-bold uppercase text-sm px-3 py-2 rounded shadow">
                  Copied to Clipboard
                </span>
              )}
            </div>
          </div>
          {/* <button
            onClick={() => handleRemove(removeFollower?._id)}
            className="btn btn-sm btn-outline btn-error"
          >
            Confirm
          </button> */}
          {/* <button
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg"
                type="button"
                // onClick={handleRemove}
                onClick={() => {
                  navigator.clipboard.writeText(this.state.textToCopy);
                }}
              >
                Confirm
              </button> */}
        </div>
      ) : null}
    </>
  );
};

export default ShareItemModal;
