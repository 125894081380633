import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import Loader from "../Shared/Loader";

const HomeBanner = ({ items, isBannerLoading }) => {
  // console.log(items)
  return (
    <div className="w-full bg-secondary rounded-[20px]">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper rounded-[20px]"
      >
        {isBannerLoading ? (
          <Loader></Loader>
        ) : (
          items?.map((item) => (
            <SwiperSlide key={item?._id}>
              <Link to={`/categoryItems/${item?._id}`}>
                <img
                  src={item?.bannerImg}
                  style={{ width: "100%", height: "auto" }}
                  className="bg-contain mx-auto rounded-[20px]"
                  alt="img"
                />
              </Link>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default HomeBanner;
