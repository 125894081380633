import { useState } from "react";
import { FaBars, FaUsers } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import RouteLink from "../../../Components/RouteLink";
import { TbTruckDelivery } from "react-icons/tb";
import { MdArticle, MdSettings } from "react-icons/md";


const Dashboard = () => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const toggleSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen);
    }
    return (
        <div className="md:flex min-h-screen bg-white dark:bg-black">


            {/* Side Bar */}
            <div className={`sidebar hidden md:block bg-orange-500 dark:bg-black min-w-[220px] px-4`}>
                <ul className="space-y-5 text-white">
                    <li><h2 className="text-2xl font-bold pt-5"> Admin Dashboard</h2></li>
                    <div className="divider bg-white h-1"></div>
                    <li><RouteLink to='allOrders'>
                        <div className=" flex items-center gap-2">
                            <TbTruckDelivery></TbTruckDelivery>
                            <p>All Orders</p>
                        </div>
                    </RouteLink>
                    </li>
                    <li><RouteLink to='allUsers'>
                        <div className=" flex items-center gap-2">
                            <FaUsers></FaUsers>
                            <p>All Users</p>
                        </div>
                    </RouteLink>
                    </li>
                    <li>
                        <RouteLink to='allItems'>
                            <div className=" flex items-center gap-2">
                                <TbTruckDelivery></TbTruckDelivery>
                                <p>Shop Items</p>
                            </div>
                        </RouteLink>
                    </li>
                    <li> <RouteLink to='allAffiliateItems'>
                        <div className=" flex items-center gap-2">
                            <TbTruckDelivery></TbTruckDelivery>
                            <p>Amazon Items</p>
                        </div>
                    </RouteLink> </li>
                    <li><RouteLink to='allArticles'>
                        <div className=" flex items-center gap-2">
                            <MdArticle></MdArticle>
                            <p>All Articles</p>
                        </div>
                    </RouteLink>
                    </li>
                    <li><RouteLink to='apiSettings'>
                        <div className=" flex items-center gap-2">
                            <MdSettings />
                            <p>API Settings</p>
                        </div>
                    </RouteLink>
                    </li>
                    <div>
                        <li><Link to='/'><button className="btn mt-24 text-white btn-warning bg-green-500">Return To Home</button></Link></li>
                    </div>
                </ul>
            </div>

            <div>
                <div>
                    <label onClick={toggleSideBar} className="md:hidden absolute z-50 btn btn-warning bg-orange-500 mt-4 ml-2"><FaBars className="text-white text-xl"></FaBars></label>
                </div>
            </div>


            {/* Mobile View Side Bar */}
            <div className={`text-white pt-20 h-auto flex flex-col absolute duration-300 ease-in left-0 shadow-md space-y-2 w-[180px] ${isSideBarOpen ? "left-0" : "left-[-700px]"} absolute min-h-full z-20 md:hidden bg-orange-500 dark:bg-black min-w-[220px] px-4`}>
                <ul className="space-y-5 text-white">
                    <li><h2 className="text-2xl font-bold pt-5"> Admin Dashboard</h2></li>
                    <div className="divider bg-white h-1"></div>
                    <li><RouteLink to='allOrders' onClick={toggleSideBar} className="text-xl font-semibold"><p >All Orders</p></RouteLink></li>
                    <li><RouteLink to='allUsers' onClick={toggleSideBar} className="text-xl font-semibold">All Users</RouteLink></li>
                    <li><RouteLink to='allItems' onClick={toggleSideBar} className="text-xl font-semibold">Shop Items</RouteLink></li>
                    <li><RouteLink to='allAffiliateItems' onClick={toggleSideBar} className="text-xl font-semibold">Affiliate Items</RouteLink></li>
                    <li><RouteLink to='allArticles' onClick={toggleSideBar} className="text-xl font-semibold">All Articles</RouteLink></li>
                    <li><RouteLink to='apiSettings' onClick={toggleSideBar} className="text-xl font-semibold">API Settings</RouteLink></li>
                    <div>
                        <li><Link to='/'><button className="btn mt-24 text-white btn-warning bg-green-500">Return To Home</button></Link></li>
                    </div>
                </ul>
            </div>

            <div className="main-content p-5 md:pt-5 pt-20 w-full">
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;