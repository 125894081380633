import React, { useEffect, useState } from "react";
import MyModal from "./MyModal";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";

const UpdateAddressModal = ({ isOpen, setIsOpen, addressId }) => {
  const {t} = useTranslation();
  const [indianStatesData, setIndianStatesData] = useState([]);
  const [userAddressData, setUserAddressData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetch("jsons/states.json")
      .then((res) => res.json())
      .then((data) => setIndianStatesData(data));
  }, []);

  const { data: profileData } = useQuery(
    ["userProfile", localStorage.getItem("user-id")],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/users/userProfile/${localStorage.getItem("user-id")}`
      );
      return response.data;
    }
  );

  useEffect(() => {
    if (profileData) {
      setUserAddressData(
        profileData?.address.find((addr) => addr?._id === addressId)
      );
    }
  }, [profileData, addressId]);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: updateAddress } = useMutation(
    async ({ data, userId, addressId }) => {
      setIsLoading(true);
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/users/updateAddress/${userId}/${addressId}`,
          data
        );
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        // Use setTimeout to ensure that isLoading is set to false even if an error occurs
        setTimeout(() => {
          setIsLoading(false);
        }, 1000); // You can adjust the timeout duration as needed
      }
    },
    {
      onSettled: () => {
        // After the mutation is settled (whether success or error), close the modal and refetch the user profile query
        setIsOpen(false);
        queryClient.invalidateQueries(["userProfile", localStorage.getItem("user-id")]);
      },
    }
  );

  const onSubmit = async (data) => {
    try {
      // Call the updateAddress mutation function
      await updateAddress({ data, userId: localStorage.getItem("user-id"), addressId });
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <MyModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t("Update Your Information")}
    >
      {isLoading ? ( // Render loading spinner if isLoading is true
        <div className="text-center">Loading...</div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off" className="space-y-3">
          <>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.deliveryName}
                className="input input-bordered w-full bg-white dark:bg-black"
                type="text"
                placeholder={t("Name of the Person to Pick the Delivery")}
                id="deliveryName"
                {...register("deliveryName")}
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.deliveryNumber}
                className="input input-bordered w-full bg-white dark:bg-black"
                type="number"
                placeholder={t("Delivery Mobile Number")}
                id="deliveryNumber"
                {...register("deliveryNumber", {
                  required: "This field is required",
                  pattern: {
                    value: /^\d{10}$/, // Regex pattern for 10-digit number
                    message: "Please enter a valid 10-digit number",
                  },
                })}
              />
              {errors.deliveryNumber && (
                <span className="text-red-500">
                  {errors.deliveryNumber.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.houseNumber}
                className="input input-bordered w-full bg-white dark:bg-black"
                type="text"
                placeholder={t("House No, Flat No")}
                id="houseNumber"
                {...register("houseNumber")}
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.roadName}
                className="input input-bordered w-full bg-white dark:bg-black "
                type="text"
                placeholder={t("Road Name, Area, Village")}
                id="roadName"
                {...register("roadName")}
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.cityName}
                className="input input-bordered w-full bg-white dark:bg-black "
                type="text"
                placeholder={t("City/Town")}
                id="cityName"
                {...register("cityName")}
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.districtName}
                className="input input-bordered w-full bg-white dark:bg-black "
                type="text"
                placeholder={t("District")}
                id="districtName"
                {...register("districtName")}
                required
              />
            </div>
            <div className="flex flex-col gap-3 my-4">
              <select
                className="input input-bordered w-full bg-white dark:bg-black "
                id="stateName"
                defaultValue={userAddressData?.stateName}
                {...register("stateName")}
                required
              >
                <option value="" disabled>
                  {t("Select State")}
                </option>
                {indianStatesData.map((state) => (
                  <option key={state?.code}>{state?.name}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <input
                defaultValue={userAddressData?.pinCode}
                className="input input-bordered w-full bg-white dark:bg-black "
                type="text"
                placeholder={t("Enter Pincode")}
                id="pinCode"
                {...register("pinCode")}
                required
              />
            </div>
          </>

          <div className="flex items-center gap-2">
            <button
              type="button"
              className="btn btn-info bg-blue-200 text-black btn-sm"
              onClick={() => {
                reset();
              }}
            >
              {t("Reset")}
            </button>
            <button
              type="submit"
              className="btn btn-warning bg-orange-500 text-white btn-sm"
              disabled={isLoading} // Disable the button while the mutation is in progress
            >
              {isLoading ? t("Saving...") : t("Save Address")}
            </button>
          </div>
        </form>
      )}
    </MyModal>
  );
};

export default UpdateAddressModal;
