import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home/Home";
import Navbar from "./Pages/Shared/Navbar";
import AllCategories from "./Pages/AllCategories/AllCategories";
import SingleItemDetails from "./Pages/Items/SingleItemDetails";
import SelectCategory from "./Pages/Items/SellItems/SelectCategory";
import AddItem from "./Pages/Items/SellItems/AddItem";
import { ToastContainer } from "react-toastify";
import EditItem from "./Pages/Items/EditItem/EditItem";
import AllItems from "./Pages/Items/AllItems";
import SubCategories from "./Pages/AllCategories/SubCategories";
import SelectSubcategory from "./Pages/Items/SellItems/SelectSubcategory";
import SubcategoryItems from "./Pages/Items/SubcategoryItems";
import CategoryItemsCard from "./Pages/Items/CategoryItemsCard/CategoryItemsCard";
import ItemDetails from "./Pages/Items/ItemDetails/ItemDetails";
import OrderDetails from "./Orders/OrderDetails";
import ContactUs from "./Pages/ContactUs/ContactUs";
import OtpLogin from "./Pages/CustomerLogin/OtpLogin";
import Profile from "./Pages/Profile/Profile";
import PlaceOrder from "./Pages/PlaceOrder/PlaceOrder";
import RefundPolicy from "./Pages/RefundPolicy/RefundPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Shipping from "./Pages/Shipping/Shipping";
import Footer from "./Pages/Shared/Footer";
import Terms from "./Terms&Condition/Terms";
import MyCart from "./Pages/MyCart/MyCart";
import Favourites from "./Pages/Favourites/Favourites";
import { Helmet } from "react-helmet-async";
import Dashboard from "./Pages/AdminDashboard/Dashboard/Dashboard";
import AllOrders from "./Pages/AdminDashboard/AllOrders/AllOrders";
import Analytics from "./Pages/AdminDashboard/Analytics/Analytics";
import AllUsers from "./Pages/AdminDashboard/AllUsers/AllUsers";
import Articles from "./Pages/Articles/Articles";
import ArticleDetails from "./Pages/Articles/ArticleDetails";
import AllArticles from "./Pages/AdminDashboard/AllArticles/AllArticles";
import ArticleForm from "./Pages/AdminDashboard/AllArticles/ArticleForm";
import APISettings from "./Pages/AdminDashboard/Settings/APISettings";
import AllItemsList from "./Pages/AdminDashboard/AllItems/AllItems";
import ItemForm from "./Pages/AdminDashboard/AllItems/ItemForm";
import AffiliatedItems from "./Pages/AdminDashboard/AffiliatedItems/AffiliateItems";
import ii18n from "./i18n";

function App() {
  
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(location)
    if (location.search.includes("?lang=")) {
      const lang = location.search.split("?lang=")[1];
      ii18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
      navigate('/');
    }
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  const noHeaderFooter = location.pathname.includes('adminDashboard');

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
    document.body.className = theme;
  }, [theme]);

  return (
    <div>
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Shop</title>
        <meta
          name="description"
          content="Explore a wide range of shop categories, discover the latest deals, popular items, and new arrivals. Find the perfect products that suit your needs and style at our online store."
        />
      </Helmet>
      {
        noHeaderFooter || <Navbar
          toggleTheme={toggleTheme}
          theme={theme}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      }

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/categories" element={<AllCategories />} />
          <Route path="/:category/subcategories" element={<SubCategories />} />
          <Route path="/items/:route" element={<AllItems />} />
          <Route
            path="/:category/:subcategory"
            element={<SubcategoryItems />}
            p
          />
          <Route path="/item/:itemId" element={<SingleItemDetails />} />
          <Route path="/add-item" element={<AddItem />} />
          <Route path="/select-category" element={<SelectCategory />} />
          <Route
            path="/:category/select-subcategories"
            element={<SelectSubcategory />}
          />
          <Route path="/edit-item/:id" element={<EditItem />} />

          <Route path="/signIn" element={<OtpLogin />} />

          <Route
            path="/category/:categoryName"
            element={<CategoryItemsCard></CategoryItemsCard>}
          ></Route>

          <Route
            path="/categoryItems/:itemId"
            element={<ItemDetails></ItemDetails>}
          ></Route>

          <Route
            path="/orderDetails"
            element={<OrderDetails></OrderDetails>}
          ></Route>

          <Route path="/contactUs" element={<ContactUs></ContactUs>}></Route>

          <Route path="/profile" element={<Profile></Profile>}></Route>

          <Route path="/placeOrder" element={<PlaceOrder></PlaceOrder>}></Route>

          <Route
            path="/refundPolicy"
            element={<RefundPolicy></RefundPolicy>}
          ></Route>

          <Route path="/articles" element={<Articles />}></Route>
          <Route path="/articles/:articleId" element={<ArticleDetails />}></Route>

          <Route
            path="/privacyPolicy"
            element={<PrivacyPolicy></PrivacyPolicy>}
          ></Route>

          <Route path="/shipping" element={<Shipping></Shipping>}></Route>

          <Route path="/terms" element={<Terms></Terms>}></Route>

          <Route path="/myCart" element={<MyCart></MyCart>}></Route>

          <Route path="/favourites" element={<Favourites></Favourites>}></Route>

          {/* <Route path="/allOrders" element={<AllOrders></AllOrders>}></Route> */}

          <Route path="/adminDashboard/*" element={<Dashboard></Dashboard>}>
            <Route path="allOrders" element={<AllOrders />} />
            <Route path="allArticles" element={<AllArticles />} />
            <Route path="allArticles/editArticle/:id" element={<ArticleForm />} />
            <Route path="allArticles/addArticle" element={<ArticleForm />} />
            <Route path="allItems" element={<AllItemsList />} />
            <Route path="allItems/addItem" element={<ItemForm/>}/>
            <Route path="allItems/editItem/:id" element={<ItemForm />} />
            <Route path="allAffiliateItems" element={<AffiliatedItems />} />
            <Route path="apiSettings" element={<APISettings />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="allUsers" element={<AllUsers />} />
          </Route>
        </Routes>

        <ToastContainer />
      </div>
      {
        noHeaderFooter || <Footer theme={theme}></Footer>
      }

    </div>
  );
}

export default App;
