import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import uploadMultipleImages from "../../../Utils/uploadMultipleImages";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading";
// import CategoryModal from "./CategoryModal";
// import "./CategoryModal.css";

const AddItem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [modal, setModal] = useState(null);
  // const category = JSON.parse(localStorage.getItem("category"));
  const navigate = useNavigate();
  const { category, subcategory } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [itemImgFiles, setItemImgFiles] = useState([]);
  const [imgMessage, setImgMessage] = useState(false);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [addStatus, setAddStatus] = useState(false);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  //Get Image URL
  // useEffect(() => {
  //   if (!itemImgFiles?.length) {
  //     setItemImgFiles([]);
  //     return;
  //   }
  //   itemImgFiles?.map((itemImgFile) => {
  //     const itemImgURLfile = URL.createObjectURL(itemImgFile);
  //     setItemImgURL(itemImgURLfile);
  //     return () => {
  //       return URL.revokeObjectURL(itemImgURLfile);
  //     };
  //   });
  // }, [itemImgFiles]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    if (itemImgFiles?.length <= 0) {
      setImgMessage(true);
    } else {
      setImgMessage(false);
      setLoading(true);
      const itemImagePathname = `vip-ware/items/${
        subcategory ? subcategory?.name : category?.collection?.name
      }/${data?.itemName}`;
      const itemImageURLs = await uploadMultipleImages(
        itemImgFiles,
        itemImagePathname
      );
      const itemInfo = {
        itemName: data?.itemName,
        categoryID: subcategory ? subcategory?._id : category?.collection?._id,
        categoryName: subcategory
          ? subcategory?.name
          : category?.collection?.name,
        description: data?.description,
        imageGallery: itemImageURLs,
        price: parseInt(data?.price),
        discPrice: parseInt(data?.price),
        lat: lat,
        lng: lng,
        makeStatus: addStatus,
        // subcategoryName: categoryDetails?.subcategory?.name,
        // brand: "Range Rover",
        // location: [lat, lng],
      };

      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      await axios
        .post(`${process.env.REACT_APP_ENDPOINT}/item`, itemInfo, config)
        .then((data) => {
          if (data.status === 200) {
            toast.success(`${data.data.itemName} is added Successfully`, {
              theme: "colored",
            });
            navigate(
              subcategory
                ? `/${category?.collection?.name
                    .toLowerCase()
                    .split(" ")
                    .join("_")}/${subcategory?.name
                    .toLowerCase()
                    .split(" ")
                    .join("_")}`
                : `/category/${category?.collection?.name
                    .toLowerCase()
                    .split(" ")
                    .join("_")}`,
              { state: { category, subcategory } }
            );
          } else {
            toast.error(`Can't Add Item`, { theme: "colored" });
          }
        })
        .finally(() => setLoading(false));
    }
  };
  if (loading) {
    return <Loading />;
  }
  console.log(category);
  // console.log(subcategory);
  return (
    <div className="App min-h-screen max-w-6xl mx-auto text-black dark:text-[#E2E2E2]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        Home / Sell Item /
        <span className="text-black dark:text-[#E2E2E2]"> Add Detail</span>
      </p>
      <div className="bg-white dark:bg-secondary rounded-[20px] p-6 sm:p-12">
        <p className="font-semibold mb-3">Details</p>
        <hr />
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-2 sm:space-y-5 mt-8"
        >
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-5">
            <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
              <img
                className="mask mask-circle object-cover w-[34px] h-[34px]"
                src={category?.collection?.logo}
                alt=" "
              />
              <p className="text-sm">{category?.collection?.name}</p>
              {/* <label
                htmlFor="select-category-modal"
                className="cursor-pointer bg-[#161616] h-6 w-6 absolute rounded flex justify-center items-center bottom-[35px] left-[130px]"
              >
                <Edit />
              </label> */}
            </div>
            {subcategory && (
              <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
                <img
                  className="mask mask-circle object-cover w-[34px] h-[34px]"
                  src={subcategory?.logo}
                  alt=" "
                />
                <p className="text-sm">{subcategory?.name}</p>
                {/* <label
                  htmlFor="select-category-modal"
                  className="cursor-pointer bg-[#161616] h-6 w-6 absolute rounded flex justify-center items-center bottom-[35px] left-[130px]"
                >
                  <Edit />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      // setImgFile(e.target.files[0]);
                    }}
                  />
                </label> */}
              </div>
            )}
          </div>
          <div className="form-control max-w-sm">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Price *
              </span>
            </label>
            <input
              type="number"
              min={0}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={"000"}
              {...register("price", {
                required: {
                  value: true,
                  message: "Price is required",
                },
              })}
            />
            <label className="label">
              {errors.price?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.price.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control max-w-lg">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Name of the Product *
              </span>
            </label>
            <input
              type="text"
              placeholder="Product Name"
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("itemName", {
                required: {
                  value: true,
                  message: "Item Name is required",
                },
              })}
            />
            <label className="label">
              {errors.itemName?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.itemName.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Description *
              </span>
              <span className="label-text-alt">{250}</span>
            </label>
            <textarea
              type="text"
              placeholder="Description"
              className="textarea textarea-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none h-24"
              {...register("description", {
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            <label className="label">
              {errors.description?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.description.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full my-6">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                Address *
              </span>
            </label>
            <Autocomplete
              apiKey={googleMapsApiKey}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder="Your Address"
              onPlaceSelected={(place) => {
                // console.log(place);
                const lat = place?.geometry?.location?.lat();
                const lng = place?.geometry?.location?.lng();
                setLat(lat);
                setLng(lng);
              }}
              // options={{
              //   // types: ["(restaurents)"],
              //   componentRestrictions: { country: "de" },
              // }}
              // {...register("address", { required: true, maxLength: 20 })}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  City *
                </span>
              </label>
              <input
                type="text"
                placeholder="City"
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("city", {
                  required: {
                    value: true,
                    message: "City is required",
                  },
                })}
              />
              <label className="label">
                {errors.city?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.city.message}
                  </span>
                )}
              </label>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  State *
                </span>
              </label>
              <input
                type="text"
                placeholder="State"
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("state", {
                  required: {
                    value: true,
                    message: "State is required",
                  },
                })}
              />
              <label className="label">
                {errors.state?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.state.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="">
            <label className="label">
              <span className="label-text dark:text-white font-semibold">
                Upload photos upto 12 *
              </span>
            </label>
            <div className="flex flex-wrap gap-3">
              {itemImgFiles &&
                [...itemImgFiles]?.map((itemImgFile, index) => {
                  return (
                    <img
                      key={index}
                      src={URL.createObjectURL(itemImgFile)}
                      alt=" "
                      className="w-[100px] h-[75px] rounded-[10px] object-cover"
                    />
                  );
                })}
              {itemImgFiles.length < 12 && (
                <label className="cursor-pointer bg-[#F6F6F6] dark:bg-[#A6A6A6] w-[100px] h-[75px] rounded-[10px] flex flex-col justify-center items-center gap-1">
                  <MdOutlineAddPhotoAlternate className="text-black h-7 w-7" />
                  <p className="font-[roboto] text-xs text-black">Add Photo</p>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setItemImgFiles([...itemImgFiles, ...e.target.files]);
                    }}
                    required
                  />
                </label>
              )}
            </div>
            {imgMessage && (
              <span className="label-text-alt text-red-600 text-sm">
                Select minimum 1 Image
              </span>
            )}
          </div>
          <div className="form-control">
            <label className="cursor-pointer label justify-start gap-3">
              <input
                onClick={() => setAddStatus(!addStatus)}
                type="checkbox"
                className={`checkbox w-4 h-4 rounded-sm border  ${
                  addStatus ? "bg-white" : "bg-transparent border-gray-400"
                }`}
              />
              <span
                className={`${
                  addStatus ? "opacity-100" : "opacity-50"
                } label-text text-sm text-black dark:text-white`}
              >
                Add product to my status
              </span>
            </label>
          </div>
          <hr className="my-8" />
          <div className="grid grid-cols-2 gap-3">
            <input
              type="button"
              onClick={() => navigate("/select-category")}
              className="btn w-full bg-transparent border-black dark:border-white text-black dark:text-white hover:bg-transparent normal-case"
              value="Cancel"
            />
            <input
              type="submit"
              className="btn w-full bg-black dark:bg-white text-white dark:text-black normal-case"
              value="Post Now"
            />
          </div>
        </form>
      </div>
      {/* <CategoryModal setModal={setModal} /> */}
    </div>
  );
};

export default AddItem;
