import React, { useEffect, useRef } from 'react';
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Loading from '../Shared/Loading'
import { Link, useParams } from 'react-router-dom';

const ArticleDetails = () => {
    const { t } = useTranslation();
    const { articleId } = useParams()
    const lang = localStorage.getItem("lang");
    const url = `${process.env.REACT_APP_BACKEND_URL}/articles/single/${articleId}?&lang=${lang}`;
    const url2 = `${process.env.REACT_APP_BACKEND_URL}/articles/trending?&lang=${lang}`;

    const articleContainerRef = useRef();

    const { data: article, isLoading } = useQuery([`article`, articleId, lang], () =>
        axios.get(url).then((data) => {
            return data.data;
        })
    );

    const { data: trendingAritcles, isLoading2 } = useQuery([`trendingAritcles`, lang], () =>
        axios.get(url2).then((data) => {
            return data.data;
        })
    );

    const scrollToSection = (sectionId) => {
        const targetSection = document.getElementById(sectionId);

        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        const incrementView = async () => {
            try {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/articles/view/${articleId}`);

            } catch (error) {
                console.log(error);
            }
        }
        incrementView();
    }, []);

    if (isLoading) {
        return <Loading />;
    } else if (isLoading2) {
        return <Loading />;
    }

    return (
        <div className='p-8 flex w-full h-full'>
            <div className='flex justify-between md:gap-5 gap-10 w-full md:flex-row flex-col'>
                <div ref={articleContainerRef} className='flex flex-col gap-5 border p-5 rounded-3xl w-full'>
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${article.imageMain}`} className='max-w-full max-h-[450px] rounded-2xl object-cover' />
                    <h1 className='text-4xl font-bold'>{article.title}</h1>
                    <div className='flex flex-col gap-2 bg-gray-900 p-5 rounded-2xl'>
                        {article ? article.sections.map((section, index) => (
                            <ul key={index} className='list-disc px-5 text-orange-500'>
                                <li id={section.id} className='font-bold cursor-pointer' onClick={() => scrollToSection(section.id)} >
                                    {section.heading}
                                </li>
                            </ul>
                        )) : <p>Server error or loading...</p>}
                    </div>
                    <div className='flex flex-col gap-5 mb-5'>
                        {article ? article.sections.map((section, index) => (
                            <div key={index} className='flex flex-col gap-3'>
                                <h1 id={section.id} className='text-3xl font-bold' >{section.heading}</h1>
                                <p>{section.content}</p>
                            </div>
                        )) : <p>Server error or loading...</p>}
                    </div>
                    <Link to={article.linkImageSecondary} className='w-full flex'>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${article.imageSecondary}`} className='w-full rounded-2xl object-cover' />
                    </Link>
                </div>
                <div className='flex flex-col gap-5 md:w-1/4 w-full'>
                    <h1 className='text-4xl font-bold'>{t("Trending Articles")}</h1>
                    {trendingAritcles ? trendingAritcles.length > 0 && trendingAritcles.map((article, index) => (
                        <div className='flex gap-3 flex-col border p-4 rounded-2xl cursor-pointer hover:bg-gray-900 duration-300' key={index}>
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/${article.imageMain}`} className='max-w-full rounded-2xl' />
                            <div className='flex flex-col gap-3'>
                                <h1 className='text-2xl font-bold'>{article.title}</h1>
                                <p>{(article.sections[0].content).substring(0, 100)}...</p>
                                <button className='bg-active mt-3 p-2 rounded-xl font-bold w-full hover:bg-orange-700'>{t("Read Article")}</button>
                            </div>
                        </div>
                    )) : <p>{t("No Articles")}</p>}
                </div>
            </div>
        </div>
    )
}

export default ArticleDetails