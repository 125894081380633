import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import Loading from '../../Shared/Loading';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const AllUsers = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const { data: users, isLoading } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`)
            return res.data;
        }
    })

    const dataToRender = users?.slice(startIndex, endIndex);
    return (
        <div className="pb-8 min-h-screen">
            <h1 className="text-4xl dark:text-white text-black font-bold">All Users : {users?.length}</h1>

            {isLoading ? (
                <Loading></Loading>
            ) : (
                <div className="overflow-x-auto mt-8 pr-4">
                    <table className="table w-full table-xs">
                        <thead>
                            <tr>
                                <th className="dark:bg-secondary">#</th>
                                <th className="dark:bg-secondary">{t("Name")}</th>
                                <th className="dark:bg-secondary">{t("Mobile Number")}</th>
                                <th className="dark:bg-secondary">{t("Address")}</th>
                                <th className="dark:bg-secondary">{t("Role")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataToRender?.map((user, index) => (
                                <tr key={startIndex + index + 1}>
                                    <td className="dark:bg-secondary">{startIndex + index + 1}</td>
                                    {/* <td className="dark:bg-secondary" style={{ whiteSpace: 'pre-line' }}>{truncateText(order.itemName, 100)}</td> */}
                                    <td className="dark:bg-secondary">{user?.name}</td>
                                    <td className="dark:bg-secondary">{user?.mobileNumber}</td>
                                    <td
                                        className="dark:bg-secondary"
                                        style={{ whiteSpace: "pre-line" }}
                                    >
                                        {user.address
                                            .map((add) => `${add?.deliveryName} ${add?.deliveryNumber} ${add?.houseNumber} ${add?.roadName} ${add?.cityName} ${add?.districtName} ${add?.pinCode}`)
                                            .join(", ")}
                                    </td>
                                    <td className="dark:bg-secondary">{user?.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div className="flex justify-end items-center gap-1 mt-5">
                <p className="join-item text-black dark:text-white">Showing {startIndex + 1} - {endIndex} of {users?.length} results</p>
                <p className="join-item text-black dark:text-white">on page {currentPage} of {Math.ceil(users?.length / itemsPerPage)}</p>
            </div>
            <div className="join grid gap-2 grid-cols-2 rounded-md w-1/2 md:w-1/4 lg:w-1/6 ml-auto mt-5 pagination">
                <button
                    className="join-item btn btn-warning text-white bg-orange-500"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous page
                </button>
                <button
                    className="join-item btn btn-success bg-green-500 text-white"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={endIndex >= users?.length}
                >
                    Next Page
                </button>
            </div>
        </div>
    );
};

export default AllUsers;