import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Loader from '../../../Pages/Shared/Loader';
import { toast } from 'react-toastify';

const APISettings = () => {
    const [form, setForm] = useState({
        shopAPI: false,
        amazonLink: false,
        both: {
            checked: false,
            percetangeOfShop: 0,
            percentageOfAmazon: 0,
        }
    });
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const url = `${process.env.REACT_APP_BACKEND_URL}/affiliate`;

    const getSettings = async () => {
        try {
            const res = await axios.get(url);
            if (res.status === 200 || res.status === 201) {
                setSettings(res.data);
                if (res.data.bothAPIs && res.data.bothAPIs.checked) {
                    setForm({
                        ...form,
                        both: {
                            ...form.both,
                            checked: true,
                            percetangeOfShop: res.data.bothAPIs.percetangeOfShop,
                            percentageOfAmazon: res.data.bothAPIs.percentageOfAmazon
                        }
                    });
                } else {
                    setForm({
                        ...form,
                        shopAPI: res.data.shopAPI,
                        amazonLink: res.data.amazonLink
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const saveSettings = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const res = await axios.put(url, form);
            if (res.status === 200) {
                toast.success(res.data.message);
                getSettings();
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error('An error occured, please try again');
        }
    }

    useEffect(() => {
        getSettings();
    }, []);

    const handleShopAPIChecked = (e) => {
        setForm({ ...form, shopAPI: e.target.checked, amazonLink: false, both: { ...form.both, checked: false } });
    };

    const handleAmazonAPIChecked = (e) => {
        setForm({ ...form, amazonLink: e.target.checked, shopAPI: false, both: { ...form.both, checked: false } });
    };

    const handleBothAPIChecked = (e) => {
        setForm({ ...form, both: { ...form.both, checked: e.target.checked, }, shopAPI: false, amazonLink: false });
    }

    const handleShopPercentageChange = (e) => {
        if (e.target.value >= 0 && e.target.value <= 100) {
            setForm({ ...form, both: { ...form.both, percetangeOfShop: e.target.value, percentageOfAmazon: 100 - e.target.value } });
        }
    };

    if (isLoading) return <Loader />

    return (
        <div className='p-10'>
            {!settings ? <p className='text-xl'>Settings not initialized</p> :
                <>
                    <h1 className='text-3xl font-bold'>Current API Settings</h1>
                    <hr className='my-4' />
                    <div className='flex flex-col gap-2 mt-2 w-full justify-center items-start'>
                        {settings.bothAPIs && settings.bothAPIs.checked ? (
                            <>
                                <h2 className='text-2xl font-bold'>Both {settings.bothAPIs.checked ? 'Enabled' : 'Disabled'}</h2>
                                {settings.bothAPIs.checked && (
                                    <div className='flex flex-col my-4'>
                                        <label htmlFor='percentageOfShop' className='text-lg font-semibold'>Percentage of Shop : {settings.bothAPIs.percetangeOfShop}</label>
                                        <label htmlFor='percentageOfAmazon' className='text-lg font-semibold mt-4'>Percentage of Amazon : {settings.bothAPIs.percentageOfAmazon}</label>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <h2 className='text-2xl font-bold'>Shop API : {settings.shopAPI ? 'Enabled' : 'Disabled'}</h2>
                                <h2 className='text-2xl font-bold'>Amazon API : {settings.amazonLink ? 'Enabled' : 'Disabled'}</h2>
                            </>
                        )}
                    </div>
                </>
            }
            <hr className='my-4' />

            <div className='mt-8 flex flex-col w-full justify-center items-start'>
                <h2 className='text-2xl font-bold'>Shop API</h2>
                <div className='flex items-center'>
                    <label htmlFor='shopAPI' className='text-lg font-semibold'>Enable Shop API</label>
                    <input type='checkbox' id='shopAPI' className='ml-5 w-5 h-5' checked={form.shopAPI} onChange={(e) => handleShopAPIChecked(e)} />
                </div>
                <h2 className='text-2xl font-bold mt-8'>Amazon API</h2>
                <div className='flex items-center'>
                    <label htmlFor='amazonAPI' className='text-lg font-semibold'>Enable Amazon API</label>
                    <input type='checkbox' id='amazonAPI' className='ml-5 w-5 h-5' checked={form.amazonLink} onChange={(e) => handleAmazonAPIChecked(e)} />
                </div>
                <h2 className='text-2xl font-bold mt-8'>Both</h2>
                <div className='flex items-center'>
                    <label htmlFor='shopAPI' className='text-lg font-semibold'>Enable Both</label>
                    <input type='checkbox' id='both' className='ml-5 w-5 h-5' onChange={(e) => handleBothAPIChecked(e)} checked={form.both.checked} />
                </div>
                {form.both.checked && (
                    <div className='flex flex-col my-4'>
                        <label htmlFor='percentageOfShop' className='text-lg font-semibold'>Percentage of Shop</label>
                        <input
                            type='number'
                            id='percentageOfShop'
                            className='w-1/4 p-2 bg-transparent border'
                            value={form.both.percetangeOfShop}
                            onChange={(e) => handleShopPercentageChange(e)} />

                        <label htmlFor='percentageOfAmazon' className='text-lg font-semibold mt-4'>Percentage of Amazon</label>
                        <input
                            type='number'
                            id='percentageOfAmazon'
                            className='w-1/4 p-2 border'
                            disabled
                            value={form.both.percentageOfAmazon}
                        />
                    </div>
                )}
                <button className='btn btn-info bg-orange-500 mt-4' onClick={(e) => saveSettings(e)}>Save Settings</button>
            </div>
        </div>
    );
};

export default APISettings;