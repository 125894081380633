import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

const SearchModal = ({ visible, onClose }) => {
  const {t} = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.trim() === "") {
          setSearchResults([]);
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/items/search?q=${searchQuery}`
        );
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleOnClose = (event) => {
    setSearchQuery("");
    onClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-40 flex pt-12 justify-center">
      <div className="bg-white dark:bg-secondary p-4 rounded-xl h-5/6 w-9/12 sm:w-1/2 md:w-1/2 x:w-1/3">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <p className="text-xl font-semibold text-black dark:text-white">{t("Search In Bharat Calendar Shop")}</p>
            <button onClick={handleOnClose} className="text-black dark:text-white">
              <RxCross2 className="w-6 h-6"></RxCross2>
            </button>
          </div>
          <input
            name="search"
            type="text"
            autoComplete="off" 
            placeholder={t("Search for Items...")}
            className="input input-bordered ring-inset w-full rounded-none text-black dark:text-white bg-white dark:bg-black"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="divider"></div>
        <div>
          {searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.slice(0,6).map((item) => (
                <div key={item._id} className="search-result">
                  <Link
                    onClick={handleOnClose}
                    to={`/categoryItems/${item?._id}`}
                    state={item}
                  >
                    <div className="flex items-center gap-1 mb-3">
                      <img
                        // src={item?.imageGallery?.[0]}
                        src={process.env.REACT_APP_BACKEND_URL + '/' + item?.imageGallery?.[0]}
                        width={"30px"}
                        height={"30px"}
                        alt="item"
                        className="rounded-full"
                      />
                      <p className="text-orange-500 underline">
                        {item?.itemName}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
