import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Loading from '../Shared/Loading'
import moment from 'moment'
import { Link } from 'react-router-dom';

const Articles = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang");

    const url = `${process.env.REACT_APP_BACKEND_URL}/articles?&lang=${lang}`;
    const { data: articles, isLoading } = useQuery([`articles`, lang], () =>
        axios.get(url).then((data) => {
            return data.data;
        })
    );

    const url2 = `${process.env.REACT_APP_BACKEND_URL}/articles/latest?&lang=${lang}`;
    const { data: latestArticles } = useQuery([`latestArticles`, lang], () =>
        axios.get(url2).then((data) => {
            return data.data
        })
    );

    if (isLoading) {
        return <Loading />;
    }

    const articlesPerPage = 5;
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

    // console.log(articles)
    // console.log(latestArticles)

    return (
        <div className={`p-8 w-full ${articles.length > 1 ? 'h-full' : 'h-screen'}`}>
            <h1 className='text-start text-4xl font-bold mb-5 ml-2'>{t("Articles")}</h1>
            <div className='flex justify-between w-full h-full gap-5'>
                <div className='flex flex-col gap-3 w-full'>
                    {currentArticles ? currentArticles.length > 0 && currentArticles.map((article, index) => (
                        <Link to={`/articles/${article._id}`} className='flex gap-3 border rounded-2xl p-3 cursor-pointer hover:bg-gray-900 duration-300' key={index}>
                            <div className='flex flex-col md:flex-row justify-between gap-5'>
                                <img src={`${process.env.REACT_APP_BACKEND_URL}/${article.imageMain}`} className='md:max-w-[400px] max-h-[300px] rounded-2xl' />
                                <div className='flex flex-col gap-3'>
                                    <h1 className='text-2xl font-bold'>{article.title}</h1>
                                    <p>{(article.sections[0].content).slice(0, 650)}...</p>
                                    <div className='flex flex-col gap-3'>
                                    <button className='bg-active mt-3 p-2 rounded-xl font-bold w-[150px] hover:bg-orange-700'>{t("Read Article")}</button>
                                    <p className='px-1 mb-1'>{t("Published on")} -- {moment(article.createdAt).format('Do MMM YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )) : <p>{t("No Articles")}</p>}
                    <div className="grid gap-3 grid-cols-2 rounded-md ml-auto mt-3">
                        <button
                            className='btn btn-warning text-white bg-orange-500'
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous page
                        </button>
                        <button
                            className='btn btn-success bg-green-500 text-white'
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={indexOfLastArticle >= articles.length}
                        >
                            Next Page
                        </button>
                    </div>
                </div>
                <div className='md:flex hidden flex-col gap-3 w-1/5'>
                    <h1 className='text-2xl font-bold'>{t("Latest Articles")}</h1>
                    {latestArticles ? latestArticles.length > 0 && latestArticles.map((article, index) => (
                        <div className='flex gap-3 flex-col border p-4 rounded-2xl cursor-pointer hover:bg-gray-900 duration-300' key={index}>
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/${article.imageMain}`} className='max-w-full rounded-2xl' />
                            <div className='flex flex-col gap-3'>
                                <h1 className='text-2xl font-bold'>{article.title}</h1>
                                <p>{(article.sections[0].content).substring(0, 70)}...</p>
                                <button className='bg-active mt-3 p-2 rounded-xl font-bold w-full hover:bg-orange-700'>{t("Read Article")}</button>
                            </div>
                        </div>
                    )) : <p>{t("No Latest Articles")}</p>}
                </div>
            </div>
        </div>
    )
}

export default Articles