import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useGetLocationName = (lat, lng) => {
  const url = `${process.env.REACT_APP_ENDPOINT}/location/get-place/${lat}/${lng}`;
  const { data: location } = useQuery(
    [`location`, lat, lng],
    () =>
      axios.get(url).then((data) => {
        // console.log(data?.data);
        if (data?.data !== undefined) {
          return data.data;
        }
      }),
    { enabled: lat && lng ? true : false }
  );
  return location;
};

export default useGetLocationName;
