import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ItemCard from "./ItemCard";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const AllItems = () => {
  const { t } = useTranslation();
  const { route } = useParams();
  const { items, message } = useLocation().state;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = items?.slice(startIndex, endIndex);

  console.log(items);
  console.log(paginatedData);

  if (paginatedData?.length === 0) {
    <p className="text-center font-[inter] text-xl">{message}</p>;
  }

  return (
    <div className="App min-h-screen">
      <Helmet>
        <title>
          Bharat Calendar Bhakthi Shop | {route?.split("-").join(" ")}
        </title>
      </Helmet>
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")}/{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {route?.split("-").join(" ")}
        </span>
      </p>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-5">
        {paginatedData?.map((item, index) => (
          <ItemCard key={index} item={item} />
        ))}
      </div>
      <div className="join grid gap-2 grid-cols-2 rounded-md w-1/2 md:w-1/4 lg:w-1/6 ml-auto mt-5 pagination">
        <button
          className="join-item btn btn-warning text-white bg-orange-500"
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Previous page
        </button>
        <button
          className="join-item btn btn-success bg-green-500 text-white"
          onClick={() => {
            if (endIndex < items?.length) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default AllItems;
