import { useLocation, useNavigate } from "react-router-dom";
import useScrollTop from "../../Hooks/useScrollTop";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.init";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Shared/Loading";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import AddAddressModal from "../Modals/AddAddressModal";
import { useState } from "react";
import UpdateAddressModal from "../Modals/UpdateAddressModal";
import Swal from "sweetalert2";
import moment from "moment";
import { useEffect } from "react";
import AddProfileModal from "../Modals/AddProfileModal";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const PlaceOrder = () => {
  const [user] = useAuthState(auth);
  const item = useLocation().state;
  const itemsArray = Array.isArray(item) ? item : [item];
  // console.log(itemsArray);
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { totalItems, totalQuantity, totalPrice } = queryString.parse(
    location.search
  );
  const totalItemsNumber = parseInt(totalItems);
  const totalQuantityNumber = parseInt(totalQuantity);
  const totalPriceNumber = parseFloat(totalPrice);
  const [isOpen, setIsOpen] = useState(true);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [addressIdToUpdate, setAddressIdToUpdate] = useState(null);
  const [isPaymentPending, setPaymentPending] = useState(false);
  const [isRazorpayLoaded, setRazorpayLoaded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPostal, setSelectedPostal] = useState("Indiapost");
  const [selectedPaymentType, setSelectedPaymentType] =
    useState("Online Payment");

  const {
    data: profileData,
    isLoading,
    refetch,
  } = useQuery(["userProfile", user?.uid], async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/users/userProfile/${user?.uid}`
    );
    return response.data;
  });

  useEffect(() => {
    if (profileData) {
      setIsOpen(false);
    }

    // Set the default address to the first address if it exists
    if (profileData?.address?.length > 0) {
      setSelectedAddress(profileData?.address[0]);
    }
  }, [profileData]);

  function openModal() {
    setIsOpen(true);
  }
  function openAddressModal() {
    setIsAddressModalOpen(true);
  }
  function openUpdateModal(addressId) {
    setIsUpdateModalOpen(true);
    setAddressIdToUpdate(addressId);
  }
  const handleDelete = (id) => {
    Swal.fire({
      title: t("Remove Address?"),
      text: t("Are you sure you want to remove this address?"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: t("Remove"),
      cancelButtonText: t("Cancel"),
      confirmButtonColor: "#db5d14",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )

        axios
          .delete(
            `${process.env.REACT_APP_BACKEND_URL}/users/deleteAddress/${user?.uid}/${id}`
          )
          .then((res) => {
            console.log(res.data);
            refetch();
          });
      }
    });
  };

  const clearCart = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/carts/deleteAll/${profileData?.mobileNumber}`
      )
      .then((res) => {
        console.log(res.data);
        refetch();
      })
      .catch((error) => {
        console.error(error);
        // Handle the error as needed
      });
  };

  const today = new Date();
  const deliveryDays = 4;
  const deliveryStartDate = new Date(
    today.getTime() + deliveryDays * 24 * 60 * 60 * 1000
  );
  const deliveryEndDate = new Date(
    deliveryStartDate.getTime() + 2 * 24 * 60 * 60 * 1000
  );
  const formattedDeliveryEndDate = deliveryEndDate.toDateString();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => setRazorpayLoaded(true);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayNow = async () => {
    if (!isRazorpayLoaded) {
      console.error("Razorpay script not loaded.");
      return;
    }

    try {
      setPaymentPending(true);
      // console.log("Items Array", itemsArray)
      const items = itemsArray?.map((item) => ({
        itemName: item?.itemName,
        price: item?.discPrice || item?.itemPrice,
        quantity: item?.quantity || item?.quantities,
      }));
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/create-order`,
        {
          items,
          amount: totalPriceNumber * 100 || item?.discPrice * 100,
          currency: "INR",
          //   email: user?.email,
          // itemName: item?.itemName,
          name: profileData?.name,
          mobileNumber: user?.mobileNumber,
          orderDate: moment(new Date().toLocaleDateString()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
          deliveryDate: formattedDeliveryEndDate,
        }
      );

      // const { id, amount, currency } = response.data;
      const { amount, currency } = response.data;

      const razorpayOptions = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        // key_secret: process.env.REACT_APP_RAZORPAY_API_SECRET,
        amount,
        currency,
        // order_id: id,
        name: "Bharat Calendar Shop",
        description: `Payment for ${items.itemName}`,
        handler: function (response) {
          // console.log("Payment successful:", response);
          if (response.razorpay_payment_id) {
            const transactionId = response.razorpay_payment_id;
            const deliveryFee = "";
            // const orderId = Math.floor(Math.random() * 1000000);
            Swal.fire({
              title: t("Thank You For Your Order"),
              html:
                t("Your Order Is Confirmed. <br/>") +
                // `<b>Your Order ID : ${orderId}</b>. <br/>` +
                t("You will recieve your product within 4-7 working days."),
              icon: "success",
              confirmButtonText: "HOME",
              confirmButtonColor: "#db5d14",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              }
            });
            saveOrderToDatabase(transactionId, deliveryFee, items);
          } else {
            alert("Payment failed");
            console.error("Payment failed:", response);
          }
        },
        prefill: {
          name: profileData?.name,
          //   email: user?.email,
          contact: user?.phoneNumber,
        },
      };
      const razorpayInstance = new window.Razorpay(razorpayOptions);
      razorpayInstance.open();
      setPaymentPending(false);
    } catch (error) {
      console.error("Error while processing payment:", error);
      setPaymentPending(false);
    }
  };

  //   const confirmPaymentAndSendEmail = async (transactionId) => {
  //     try {
  //       await axios.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/payment/confirm-payment`,
  //         {
  //           transactionId: transactionId,
  //           itemName: item.itemName,
  //           email: user.email,
  //           amount: item.discPrice * 100,
  //           currency: "INR",
  //           name: user.displayName,
  //           orderDate: moment(new Date().toLocaleDateString()).format(
  //             "MMMM Do YYYY"
  //           ),
  //           deliveryDate: formattedDeliveryEndDate,
  //         }
  //       );

  //       //   navigate("/orderDetails");
  //       saveOrderToDatabase(transactionId);
  //     } catch (error) {
  //       console.error("Error confirming payment and sending email:", error);
  //     }
  //   };

  const saveOrderToDatabase = async (transactionId, deliveryFee, items) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/orders/save-order`,
        {
          transactionId,
          // itemName: item?.itemName,
          items,
          amount: totalPriceNumber || item?.discPrice,
          deliveryFee,
          pickupPerson: selectedAddress?.deliveryName,
          addressMobileNumber: selectedAddress?.deliveryNumber,
          deliveryAddress: `${selectedAddress?.houseNumber}, ${selectedAddress?.roadName}, ${selectedAddress?.cityName}, ${selectedAddress?.districtName}, ${selectedAddress?.stateName} - ${selectedAddress?.pinCode}`,
          postalMethod: selectedPostal,
          paymentType: selectedPaymentType,
          //   email: user.email,
          // orderDate: moment(new Date().toISOString()).format(
          //   "MMMM Do YYYY, h:mm:ss a"
          // ),
          orderDate: new Date().toISOString(),
          userName: profileData?.name,
          userMobileNumber: user?.phoneNumber,
        }
      );

      console.log("Order details saved in the database:", response.data);
    } catch (error) {
      console.error("Error while saving order details in the database:", error);
    }
  };

  const handleConfirmOrder = () => {
    const darkModeColor = "black";
    const lightModeColor = "#FFFFFF";
    const swalConfig = {
      title: t("Confirm Order?"),
      text: t("Your order will be confirmed"),
      icon: "warning",
      confirmButtonText: t("Yes, Confirm it!"),
      confirmButtonColor: "#228B22",
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
      cancelButtonColor: "#d33",
      reverseButtons: true,
    };

    if (localStorage.getItem("theme") === "light") {
      swalConfig.background = lightModeColor;
      swalConfig.color = darkModeColor;
    } else {
      swalConfig.background = darkModeColor;
      swalConfig.color = lightModeColor;
    }

    const transactionId = "";
    const deliveryFee = "50";
    const items = itemsArray?.map((item) => ({
      itemName: item?.itemName,
      price: item?.discPrice || item?.itemDiscPrice,
      quantity: item.quantity || 1,
    }));
    Swal.fire(swalConfig).then((result) => {
      if (result.isConfirmed) {
        saveOrderToDatabase(transactionId, deliveryFee, items);
        clearCart();

        const swalConfig2 = {
          title: t("Thank You For Your Order"),
          html:
            t("Your Order Is Confirmed. <br/>") +
            t("You will receive your product within 4-7 working days."),
          icon: "success",
          confirmButtonText: t("HOME"),
          confirmButtonColor: "#db5d14",
        };
        if (localStorage.getItem("theme") === "light") {
          swalConfig2.background = lightModeColor;
          swalConfig2.color = darkModeColor;
        } else {
          swalConfig2.background = darkModeColor;
          swalConfig2.color = lightModeColor;
        }

        Swal.fire(swalConfig2).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    });
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="min-h-screen pb-10">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Place Order</title>
      </Helmet>
      <div className="p-8 bg-orange-500">
        <h1 className="text-center font-semibold text-white text-3xl">
          {t("Place Order")}
        </h1>
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="lg:col-span-2">
          <div className="shadow-xl rounded-lg p-2">
            <h2 className="font-semibold mb-2">
              {t("Select Delivery Address")}:
            </h2>
            {profileData?.address?.map((addr, index) => (
              <div
                key={index}
                onClick={() => handleAddressChange(addr)}
                className="cursor-pointer flex text-sm mb-2 flex-row justify-between gap-2 rounded-lg card p-2"
                style={{
                  border: "1px solid orange",
                }}
              >
                <label className="flex cursor-pointer items-center gap-3">
                  <input
                    name="addr"
                    type="radio"
                    value={addr}
                    checked={selectedAddress === addr}
                    onChange={() => handleAddressChange(addr)}
                    className="cursor-pointer mr-2 radio-xs radio radio-warning"
                  />

                  <div className="cursor-pointer ">
                    <p className="font-semibold underline">
                      {t("Address")}: {index + 1}
                    </p>
                    <p>{addr?.deliveryName || ""}</p>
                    <p className="font-bold">{addr?.deliveryNumber || ""}</p>
                    <p>
                      {addr?.houseNumber || ""}, {addr?.roadName || ""},{" "}
                      {addr?.cityName || ""}, {addr?.districtName || ""},{" "}
                      {addr?.stateName || ""} - {addr?.pinCode}
                    </p>
                  </div>
                </label>

                <div className="flex justify-center gap-2">
                  <button
                    className="btn btn-sm btn-success btn-circle hover:bg-green-700"
                    onClick={() => openUpdateModal(addr._id)}
                  >
                    <FaEdit className="text-white"></FaEdit>
                  </button>
                  <button
                    className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700"
                    onClick={() => handleDelete(addr._id)}
                  >
                    <FaTrash className="text-white"></FaTrash>
                  </button>
                </div>
              </div>
            ))}
            {profileData ? (
              <div className="mt-4">
                <button
                  onClick={() => openAddressModal()}
                  className="btn btn-warning btn-sm bg-orange-500 text-white capitalize"
                >
                  <FaPlus className="text-xs"></FaPlus>
                  {t("Add Address")}
                </button>
              </div>
            ) : (
              <div className="mt-4">
                <button
                  onClick={() => openModal()}
                  className="btn btn-warning btn-sm bg-orange-500 text-white capitalize"
                >
                  <FaPlus className="text-xs"></FaPlus>
                  {t("Add Information")}
                </button>
              </div>
            )}
          </div>

          <div className="shadow-xl rounded-lg p-2 mt-4">
            <h2 className="font-semibold mb-2">
              {t("Preferred Postal Method")}:
            </h2>
            <div
              onClick={() => setSelectedPostal("Indiapost")}
              className="cursor-pointer flex text-sm mb-2 flex-row items-center gap-2 rounded-lg card p-2"
              style={{
                border: "1px solid orange",
              }}
            >
              <label className="cursor-pointer flex items-center gap-2">
                <input
                  name="Indiapost"
                  type="radio"
                  value={"Indiapost"}
                  checked={selectedPostal === "Indiapost"}
                  onChange={() => setSelectedPostal("Indiapost")}
                  className="cursor-pointer mr-2 radio-xs radio radio-warning"
                />
                <div>
                  <p>{t("Indiapost")}</p>
                </div>
              </label>
            </div>
            <div
              onClick={() => setSelectedPostal("DTDC")}
              className="cursor-pointer flex text-sm mb-2 flex-row items-center gap-2 rounded-lg card p-2"
              style={{
                border: "1px solid orange",
              }}
            >
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  name="dtdc"
                  type="radio"
                  value={"DTDC"}
                  checked={selectedPostal === "DTDC"}
                  onChange={() => setSelectedPostal("DTDC")}
                  className="cursor-pointer mr-2 radio-xs radio radio-warning"
                />
                <div>
                  <p>{t("DTDC")}</p>
                </div>
              </label>
            </div>
          </div>

          <div className="shadow-xl rounded-lg p-2 mt-4">
            <h2 className="font-semibold mb-2">{t("Payment Type")}:</h2>
            <div
              onClick={() => setSelectedPaymentType("Online Payment")}
              className="cursor-pointer flex text-sm mb-2 flex-row items-center gap-2 rounded-lg card p-2"
              style={{
                border: "1px solid orange",
              }}
            >
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  name="online"
                  type="radio"
                  value={"Online Payment"}
                  checked={selectedPaymentType === "Online Payment"}
                  onChange={() => setSelectedPaymentType("Online Payment")}
                  className="cursor-pointer mr-2 radio-xs radio radio-warning"
                />
                <div>
                  <p>{t("ONLINE PAYMENT (FREE DELIVERY)")}</p>
                  <p className="text-xs">
                    {t("Payment Method")}:{" "}
                    {t(
                      "Debit / Credit / ATM Card / Net Banking / Other UPI Apps"
                    )}
                  </p>
                  <p className="text-xs">
                    {t("Delivery Time : 4 - 7 Working Days")}
                  </p>
                </div>
              </label>
            </div>

            <div
              onClick={() => setSelectedPaymentType("Cash On Delivery")}
              className="cursor-pointer flex text-sm mb-2 flex-row items-center gap-2 rounded-lg card p-2"
              style={{
                border: "1px solid orange",
              }}
            >
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  name="cash"
                  type="radio"
                  value={"Cash On Delivery"}
                  checked={selectedPaymentType === "Cash On Delivery"}
                  onChange={() => setSelectedPaymentType("Cash On Delivery")}
                  className="cursor-pointer mr-2 radio-xs radio radio-warning"
                />
                <div>
                  <p>{t("CASH ON DELIVERY (+₹50 DELIVERY FEE)")}</p>
                  <p className="text-xs">
                    {t("Delivery Time : 4 - 7 Working Days")}
                  </p>
                </div>
              </label>
            </div>
          </div>
          <div className="mt-3">
            {selectedPaymentType === "Online Payment" ? (
              <button
                className="btn btn-sm btn-block btn-warning bg-orange-500 font-bold text-white"
                onClick={handlePayNow}
                disabled={
                  isPaymentPending ||
                  !isRazorpayLoaded ||
                  !selectedAddress ||
                  !selectedPostal ||
                  !selectedPaymentType
                }
              >
                {isPaymentPending ? t("Processing Payment...") : t("Pay Now")}
              </button>
            ) : (
              <button
                className="btn btn-sm btn-block btn-warning bg-orange-500 font-bold text-white"
                onClick={handleConfirmOrder}
                disabled={
                  !selectedAddress || !selectedPostal || !selectedPaymentType
                }
              >
                {t("Confirm Order")}
              </button>
            )}
          </div>
        </div>

        <div className=" mt-2 sm:mt-4  w-full  order-first sm:order-last">
          <div
            className="shadow-xl mx-6 mt-8  p-4 rounded-lg"
            style={{
              border: "1px solid orange",
            }}
          >
            <h2>{t("PRICE DETAILS")}</h2>
            <div className="divider"></div>
            <div>
              <div className="flex items-center justify-between">
                <p>{t("Total Items")}</p>
                <p>{totalItemsNumber || 1}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>{t("Total Items Quantity")}</p>
                <p>{totalQuantityNumber || 1}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>{t("Delivery Charges")}</p>
                {selectedPaymentType === "Online Payment" ? "FREE" : "50"}
              </div>
              <div className="divider"></div>
              <div className="flex items-center justify-between">
                <p>{t("Total Amount")}</p>
                <p>
                  ₹
                  {selectedPaymentType === "Online Payment"
                    ? totalPriceNumber || item?.discPrice
                    : totalPriceNumber + parseInt(50) ||
                    parseInt(item.discPrice) + parseInt(50)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddProfileModal isOpen={isOpen} setIsOpen={setIsOpen}></AddProfileModal>
      <AddAddressModal
        isOpen={isAddressModalOpen}
        setIsOpen={setIsAddressModalOpen}
      ></AddAddressModal>
      <UpdateAddressModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        addressId={addressIdToUpdate}
      ></UpdateAddressModal>
    </div>
  );
};

export default PlaceOrder;
