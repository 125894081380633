import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.init";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Favourites = () => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const [myFavorites, setMyFavorites] = useState([]);
  console.log(myFavorites);
  const url = user
    ? `${process.env.REACT_APP_BACKEND_URL}/favourites/get-favourites?mobileNumber=${user.phoneNumber}`
    : null;

  // Load favorites from local storage when the component mounts
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites"));
    if (storedFavorites) {
      setMyFavorites(storedFavorites);
    }
  }, []);

  // Define the useQuery hook
  const queryHook = useQuery({
    queryKey: ["get-favourites", user?.phoneNumber],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data;
    },
  });
  const {
    data: favourites,
    isLoading,
    refetch,
  } = user ? queryHook : { data: null, isLoading: false, refetch: () => { } };

  const removeFromFavorites = (item) => {
    const darkModeColor = "black";
    const lightModeColor = "#FFFFFF";
    const swalConfig = {
      title: t("Remove Favorite?"),
      text: t("Are you sure you want to remove this product!"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Remove"),
      confirmButtonColor: "#db5d14",
    };
    if (localStorage.getItem("theme") === "light") {
      swalConfig.background = lightModeColor;
      swalConfig.color = darkModeColor;
    } else {
      swalConfig.background = darkModeColor;
      swalConfig.color = lightModeColor;
    }

    Swal.fire(swalConfig).then((result) => {
      if (result.isConfirmed) {
        // Compare items by their itemId property
        const updatedFavorites = myFavorites?.filter(
          (favorite) => favorite.itemId !== item?.itemId
        );
        setMyFavorites(updatedFavorites);
        refetch();
        // Update localStorage
        localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
        console.log("refetch callled");
        toast("Item Removed From Favourites");

        if (user) {
          const mobileNumber = user?.phoneNumber;
          axios
            .delete(
              `${process.env.REACT_APP_BACKEND_URL}/favourites/${mobileNumber}/${item?.itemId}`
            )
            .then(() => {
              // If the delete request is successful, you can call refetch here
              queryHook.refetch();
            })
            .catch((error) => {
              // Handle any errors from the delete request
              console.error("Error removing item:", error);
            });
        }
      }
    });
  };

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Favorites</title>
      </Helmet>
      {user ? (
        // User is logged in, display favorites from the API
        favourites && favourites.length === 0 ? (
          <div className="min-h-screen">
            <p className="text-xl text-center mt-32 text-orange-500 font-semibold">
              {t("No Favourite Items")}
            </p>
          </div>
        ) : isLoading ? (
          <Loading></Loading>
        ) : (
          favourites?.map((fav, index) => (
            <div
              key={index}
              className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mt-12 justify-center gap-8"
            >
              {fav?.items?.map((item, index) => (
                <div
                  key={index}
                  className="card card-compact mx-auto w-full bg-white dark:bg-secondary shadow-xl"
                >
                  <AiFillHeart
                    onClick={() => removeFromFavorites(item)}
                    className="dark:text-red-500 text-black cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                  ></AiFillHeart>

                  <Link to={`/categoryItems/${item?.itemId}`}>
                    <div className="px-2 py-4 space-y-4">
                      <h2
                        className="card-title text-[black] dark:text-white
 mt-5 text-sm sm:text-lg md:text-xl lg:text-xl xl:text-xl"
                      >
                        <div
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item?.itemName}
                        </div>
                      </h2>
                      {item?.availability === true ? (
                        <p className="bg-green-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                          In Stock
                        </p>
                      ) : (
                        <p className="bg-red-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                          Out Of Stock
                        </p>
                      )}

                      <div className="w-full xs:w-[185px]  sm:w-[270px] md:w-[250px] lg:w-[200px] xl:w-[220px] mx-auto">
                        <Swiper
                          pagination={{
                            dynamicBullets: true,
                          }}
                          autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay, Pagination]}
                          className="mySwiper"
                        >
                          {item?.imageGallery?.map((image, index) => (
                            <SwiperSlide key={index}>
                              <img
                                src={process.env.REACT_APP_BACKEND_URL + '/' + image}
                                // src={image}
                                alt={"item"}
                                className="max-w-sm w-full rounded-lg shadow-2xl mx-auto"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>

                      <div className="text-2xl text-center items-center flex justify-center gap-1 sm:gap-2 ">
                        <div className="flex justify-center items-center text-[9px] xs:text-[11px] sm:text-[22px]">
                          <div className="font-semibold">
                            ₹ {item?.discPrice}{" "}
                          </div>
                        </div>
                        <div className="flex justify-center text-[8px] xs:text-[10px] sm:text-[20px] line-through items-center">
                          <div className="font-semibold">₹ {item?.price} </div>
                        </div>
                        <div className="text-[9px] xs:text-[10px] sm:text-[20px] text-orange-500 font-semibold">
                          (
                          {(
                            ((item?.price - item?.discPrice) / item?.price) *
                            100
                          ).toFixed(2)}
                          % off )
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          ))
        )
      ) : // User is not logged in, display favorites from local storage
        myFavorites && myFavorites.length === 0 ? (
          <div className="min-h-screen">
            <p className="text-xl text-center mt-32 text-orange-500 font-semibold">
              No Favourite Items
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mt-12 justify-center gap-8">
            {myFavorites?.map((item, index) => (
              <div
                key={index}
                className="card card-compact mx-auto w-full bg-white dark:bg-secondary shadow-xl"
              >
                <AiFillHeart
                  onClick={() => removeFromFavorites(item)}
                  className="dark:text-red-500 text-black cursor-pointer absolute right-2 px-2 text-[42px] font-primary rounded-lg p-1"
                ></AiFillHeart>

                <Link to={`/categoryItems/${item?.itemId}`}>
                  <div className="px-2 py-4 space-y-4">
                    <h2
                      className="card-title text-[black] dark:text-white
     mt-5 text-sm sm:text-lg md:text-xl lg:text-xl xl:text-xl"
                    >
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item?.itemName}
                      </div>
                    </h2>
                    {item?.availability === true ? (
                      <p className="bg-green-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        In Stock
                      </p>
                    ) : (
                      <p className="bg-red-600 absolute left-4 px-2 -top-8 font-primary rounded-lg text-white p-1">
                        Out Of Stock
                      </p>
                    )}

                    <div className="w-full xs:w-[185px]  sm:w-[270px] md:w-[250px] lg:w-[200px] xl:w-[220px] mx-auto">
                      <Swiper
                        pagination={{
                          dynamicBullets: true,
                        }}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                      >
                        {item?.imageGallery?.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={process.env.REACT_APP_BACKEND_URL + '/' + image}
                              // src={image}
                              alt={"item"}
                              className="max-w-sm w-full rounded-lg shadow-2xl mx-auto"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="text-2xl text-center items-center flex justify-center gap-1 sm:gap-2 ">
                      <div className="flex justify-center items-center text-[9px] xs:text-[11px] sm:text-[22px]">
                        <div className="font-semibold">₹ {item?.discPrice} </div>
                      </div>
                      <div className="flex justify-center text-[8px] xs:text-[10px] sm:text-[20px] line-through items-center">
                        <div className="font-semibold">₹ {item?.price} </div>
                      </div>
                      <div className="text-[9px] xs:text-[10px] sm:text-[20px] text-orange-500 font-semibold">
                        (
                        {(
                          ((item?.price - item?.discPrice) / item?.price) *
                          100
                        ).toFixed(2)}
                        % off )
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default Favourites;
