import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const AllCategories = () => {
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_BACKEND_URL}/category`;
  const { data: categories, isLoading } = useQuery([`categories`], () =>
    axios.get(url).then((data) => {
      return data.data;
    })
  );
  // console.log(categories);
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | All Categories</title>
      </Helmet>
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("All Categories")}
        </span>
      </p>
      <div className="grid grid-cols-2 xs:grid-cols-3 gap-8">
        {categories?.map((category, index) => (
          <Link
            to={`/category/${category.name.split(" ").join("-")}`}
            key={index}
            onClick={() => {
              localStorage.setItem(
                "category",
                JSON.stringify(category.collection)
              );
            }}
          >
            <div className="card p-2 sm:p-5 shadow-xl flex flex-col md:flex-row md:justify-center items-center gap-2 md:gap-3 w-full">
              <img
                src={category?.logo}
                className="w-full rounded-2xl md:w-6/12"
                alt=" "
              />
              <div className="md:w-8/12">
                <h1 className="xs:text-left text-[14px] xs:text-[16px] lg:[20px] md:text-base font-[poppins] font-medium mx-[2px] xs:mx-0">
                  {t(category?.name)}
                </h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
