import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import AccessItemDiv from "../Shared/AccessItemDiv";

const ItemCard = ({ item, setSearchQuery }) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const category = JSON.parse(localStorage.getItem("category"));
  // console.log(item);
  return (
    <Link
      to={`/categoryItems/${item?._id}`}
      state={item}
      className="card flex flex-col justify-between py-3 xs:py-6 space-y-2 my-3 bg-white dark:bg-secondary shadow-md rounded-xl sm:rounded-[20px] h-36 w-28 xs:h-56 xs:w-48 mx-auto cursor-pointer"
    >
      <figure>
        <img
          src={process.env.REACT_APP_BACKEND_URL + '/' + item?.imageGallery[0]}
          // src={item?.imageGallery?.[0]}
          alt="items"
          className="h-[80px] w-[80px] xs:h-[123px] xs:w-[123px] object-cover rounded-lg"
        />
      </figure>
      {item?.availability === true ? (
        <p className="bg-green-600 text-[12px] absolute left-4 px-1 -top-5 font-primary rounded-lg text-white p-1">
          {t("In Stock")}
        </p>
      ) : (
        <p className="bg-red-600 absolute text-[12px] left-4 px-2 -top-5 font-primary rounded-lg text-white p-1">
          {t("Out Of Stock")}
        </p>
      )}

      <div className="text-center text-[8px] xs:text-sm font-[poppins] px-[2px]">
        <p className="font-semibold truncate px-6">{item?.itemName}</p>
        <p className="font-[inter] font-medium flex justify-center items-center gap-[2px] xs:gap-2">
          <span className="text-[8px] xs:text-sm">₹{item?.discPrice}</span>
          <span className="text-[#9D9D9D] text-[6px] xs:text-xs line-through">
            ₹{item?.price}
          </span>
        </p>
      </div>
    </Link>
  );
};

export default ItemCard;
