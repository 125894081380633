import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const Shipping = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Shipping</title>
      </Helmet>
      <div className="p-8 bg-orange-500">
        <h1 className="text-center font-semibold text-3xl text-white">
          {t("Shipping")}
        </h1>
      </div>
      <div className="px-8 mt-4 text-justify dark:text-white text-gray-500">
        <p>
          {t(
            "SHIPPING & DELIVERY Information We Strive to Deliver products ordered through Bharat Calendar Bhakthi Shop in excellent condition wrapped up in Secure pack. Time to Shipping Payment made via Online or Cash on Delivery 4 to 7 Working Days Note: Working days mentioned above are excluding Saturdays, Sundays and Holidays."
          )}
        </p>
      </div>
    </div>
  );
};

export default Shipping;
