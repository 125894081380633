import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Loading from '../../../Pages/Shared/Loader';
import ItemForm from './ItemForm';
import CategoryForm from './CategoryForm';
import { toast } from 'react-toastify';

const AffiliatedItems = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: '' })
    const [categories, setCategories] = useState([])
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState({ mode: '', id: '' })
    const [mainMode, setMainMode] = useState('items')
    const [dataToRender, setDataToRender] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('All')
    const itemsPerPage = 20;

    const url = `${process.env.REACT_APP_BACKEND_URL}/affiliate/allItems?lang=en`;
    const url2 = `${process.env.REACT_APP_BACKEND_URL}/category/amazon`;

    const handleDelete = async () => {
        try {
            setLoading(true)
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/affiliate/deleteItem/${deleteModal.id}`)
            if (res.status === 200) {
                setDeleteModal({ show: false, id: '' })
                setLoading(false)
                toast.success('Item deleted successfully')
                getItems()
            }
        } catch (error) {
            console.error(error.response.data.message)
            setLoading(false)
            toast.error(error.response.data.message)
        }
    }

    const deleteCategory = async () => {
        try {
            setLoading(true)
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/category/amazon/${deleteModal.id}`)
            if (res.status === 200) {
                setDeleteModal({ show: false, id: '' })
                setLoading(false)
                toast.success('Category deleted successfully')
                getCategories()
            }
        } catch (error) {
            console.log(error.response.data.message)
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const getCategories = async () => {
        try {
            const response = await axios.get(url2)
            if (response.status === 200) {
                setCategories(response.data)
            }
        } catch (error) {
            console.log(error)
            toast.error('Error fetching categories')
        }
    }

    const getItems = async () => {
        try {
            const response = await axios.get(url)
            if (response.status === 200) {
                setItems(response.data)
            }
        } catch (error) {
            console.log(error)
            toast.error('Error fetching items')
        }
    }

    const sortItems = async () => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/affiliate/sortAll`);
            toast.success('Items sorted successfully', {
                autoClose: 2000,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false
            });
            await getItems();
        } catch (error) {
            console.error('Error sorting items:', error);
        } finally {
            setLoading(false);
        }
    };

    const sortCategories = async () => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/category/sortAllAmazon`);
            toast.success('Categories sorted successfully', {
                autoClose: 2000,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false
            });
            await getCategories();
        } catch (error) {
            console.error('Error sorting categories:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePositionChange = async (id, direction) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/affiliate/movePosition/${id}?direction=${direction}`);
            await getItems();
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: 2000,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false
            });
            console.error('Error changing item position:', error);
        }
    };

    const handleCategoryPositionChange = async (id, direction) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/category/movePositionAmazon/${id}?direction=${direction}`);
            await getCategories();
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: 2000,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false
            });
            console.error('Error changing category position:', error);
        }
    }

    useEffect(() => {
        setCurrentPage(1)
        getCategories()
        getItems()
    }, [mainMode])

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    useEffect(() => {
        if (selectedCategory === 'All') {
            setDataToRender(items?.slice(startIndex, endIndex));
            return;
        }
        const filteredItems = items?.filter((item) => categories.find((c) => c._id === item.category)?.name === selectedCategory)
        setDataToRender(filteredItems?.slice(startIndex, endIndex));
    }, [currentPage, items, selectedCategory])

    if (loading) return <Loading />

    return (
        <div className='md:p-10 p-2'>
            <div className='flex justify-between w-full mb-3'>
                <h1 className='font-bold text-3xl'>
                    {mainMode === 'categories' ? 'Amazon Categories' : 'Amazon Items'}: {" "}
                    {mainMode === 'items' ? items?.length : categories?.length}
                </h1>
                <div className='flex gap-5'>
                    {mainMode === 'categories' && <button className='btn btn-warning bg-orange-500 text-white' onClick={() => setMainMode('items')}>Edit Items</button>}
                    {mainMode === 'items' && <button className='btn btn-warning bg-orange-500 text-white' onClick={() => setMainMode('categories')}>Edit Categories</button>}
                    {mainMode === 'categories' ?
                        <button className='btn btn-warning bg-green-500 text-white' onClick={sortCategories}>Sort Categories</button>
                        :
                        <button className='btn btn-warning bg-green-500 text-white' onClick={sortItems}>Sort Items</button>
                    }
                    {mainMode === 'categories' ?
                        <button className='btn btn-warning bg-orange-500 text-white' onClick={() => setMode({ mode: 'addCategory' })}>Add Category</button>
                        :
                        <button className='btn btn-warning bg-orange-500 text-white' onClick={() => setMode({ mode: 'add' })}>Add Item</button>
                    }
                </div>
            </div>
            {mainMode === 'items' && <>
                <div className="flex gap-5">
                    <label className='dark:text-white text-xl'>Filter by category</label>
                    <select className='dark:bg-secondary text-xl dark:text-white' onChange={(e) => setSelectedCategory(e.target.value)}>
                        <option value='All'>All</option>
                        {categories?.map((category) => (
                            <option className='dark:bg-secondary dark:text-white' key={category._id} value={category?.name}>
                                {category?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="overflow-x-auto mt-8 pr-4">
                    <table className="table text-center w-full">
                        <thead>
                            <tr className='dark:bg-secondary'>
                                <th className="dark:bg-secondary">#</th>
                                <th className='dark:bg-secondary'>ASIN</th>
                                <th className='dark:bg-secondary'>Image</th>
                                <th className='dark:bg-secondary'>Title</th>
                                <th className='dark:bg-secondary'>Position</th>
                                <th className='dark:bg-secondary'>Category</th>
                                <th className='dark:bg-secondary'>Prices</th>
                                <th className='dark:bg-secondary'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataToRender && dataToRender.length > 0 ? dataToRender?.map((item, index) => (
                                <tr key={startIndex + index + 1} className='dark:bg-secondary'>
                                    <td className='dark:bg-secondary'>{startIndex + index + 1}</td>
                                    <td className='dark:bg-secondary'>{item.asin}</td>
                                    <td className='dark:bg-secondary flex items-center justify-center w-full'>
                                        <img src={item.images} alt={item.title} className='w-full h-full max-w-[150px] max-h-[150px]' />
                                    </td>
                                    <td className='dark:bg-secondary whitespace-normal max-w-[150px]'>{item.title}</td>
                                    <td className='dark:bg-secondary'>
                                        {item.position ?
                                            <div className='flex flex-col items-center gap-2'>
                                                <div
                                                    className='flex items-center p-2 rounded-lg bg-gray-700 hover:cursor-pointer hover:bg-gray-500 '
                                                    onClick={() => handlePositionChange(item._id, 'up')}
                                                >
                                                    <FaChevronUp />
                                                </div>
                                                <p>{item.position}</p>
                                                <div className='flex items-center p-2 rounded-lg bg-gray-700 hover:cursor-pointer hover:bg-gray-500 '
                                                    onClick={() => handlePositionChange(item._id, 'down')}
                                                >
                                                    <FaChevronDown />
                                                </div>
                                            </div>
                                            :
                                            <p>please sort items</p>
                                        }
                                    </td>
                                    <td className='dark:bg-secondary'>{categories.find((c) => c._id === item.category)?.name}</td>
                                    <td className='dark:bg-secondary'>
                                        <div className='flex flex-col items-center justify-center gap-3'>
                                            <p>{item.price}</p>
                                            <p>{item.discountedPrice}</p>
                                        </div>
                                    </td>
                                    <td className='dark:bg-secondary'>
                                        <div className='flex justify-evenly'>
                                            <button className='btn btn-warning bg-green-500 text-white' onClick={() => setMode({ mode: 'edit', id: item._id })}>Edit</button>
                                            <button className='btn btn-warning bg-red-500 text-white' onClick={() => setDeleteModal({ show: true, id: item._id })}>Delete</button>
                                        </div>
                                        {deleteModal && deleteModal.show && deleteModal.id === item._id &&
                                            <div className='fixed z-50 w-full h-full top-0 left-0 flex justify-center items-center backdrop-blur-sm'>
                                                <div className='flex flex-col bg-gray-100 shadow-xl dark:bg-slate-800 p-5 rounded-md'>
                                                    <h1 className='font-bold text-3xl'>Are you sure you want to delete this?</h1>
                                                    <div className='flex justify-end gap-5 mt-5'>
                                                        <button
                                                            className='btn btn-warning bg-red-500 text-white'
                                                            onClick={() => setDeleteModal({ show: false, id: '' })}
                                                            disabled={loading}
                                                        >No</button>
                                                        <button
                                                            className='btn btn-success bg-green-500 text-white'
                                                            onClick={() => handleDelete(deleteModal.id)}
                                                            disabled={loading}
                                                        >Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            )) : <tr className='text-center dark:bg-secondary'> <td colSpan='7' className='dark:bg-secondary'>No items found</td> </tr>}
                        </tbody>
                    </table>
                    <div className="flex justify-end items-center gap-1 mt-5">
                        <p className="join-item text-black dark:text-white">Showing {startIndex + 1} - {endIndex} of {items?.length} items -</p>
                        <p className="join-item text-black dark:text-white">on page {currentPage} of {Math.ceil(items?.length / itemsPerPage)}</p>
                    </div>
                    <div className='flex justify-end gap-5 mt-5'>
                        <button
                            className='btn btn-warning bg-orange-500 text-white'
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >Previous</button>
                        <button
                            className='btn btn-warning bg-green-500 text-white'
                            onClick={() => setCurrentPage((prev) => (prev + 1))}
                            disabled={dataToRender?.length < itemsPerPage}
                        >Next</button>
                    </div>
                </div>
            </>}
            {mainMode === 'categories' &&
                <div className="overflow-x-auto mt-8 pr-4">
                    <table className="table text-center mt-5 w-full">
                        <thead>
                            <tr className=''>
                                <th className='dark:bg-secondary'>#</th>
                                <th className='dark:bg-secondary'>Name</th>
                                <th className='dark:bg-secondary'>Logo</th>
                                <th className='dark:bg-secondary'>Position</th>
                                <th className='dark:bg-secondary'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories && categories.length > 0 ? categories?.map((category, index) => (
                                <tr key={startIndex + index + 1} className='dark:bg-secondary'>
                                    <td className='dark:bg-secondary'>{startIndex + index + 1}</td>
                                    <td className='dark:bg-secondary'>{category.name}</td>
                                    <td className='dark:bg-secondary flex items-center justify-center w-full'>
                                        <img src={process.env.REACT_APP_BACKEND_URL + '/' + category.logo} alt={category.name} className='w-40 h-40' />
                                    </td>
                                    <td className='dark:bg-secondary'>
                                        {category.position ?
                                            <div className='flex flex-col items-center gap-2'>
                                                <div
                                                    className='flex items-center p-2 rounded-lg bg-gray-700 hover:cursor-pointer hover:bg-gray-500 '
                                                    onClick={() => handleCategoryPositionChange(category._id, 'up')}
                                                >
                                                    <FaChevronUp />
                                                </div>
                                                <p>{category.position}</p>
                                                <div className='flex items-center p-2 rounded-lg bg-gray-700 hover:cursor-pointer hover:bg-gray-500 '
                                                    onClick={() => handleCategoryPositionChange(category._id, 'down')}
                                                >
                                                    <FaChevronDown />
                                                </div>
                                            </div>
                                            :
                                            <p>please sort categories</p>
                                        }
                                    </td>
                                    <td className='dark:bg-secondary'>
                                        <div className='flex gap-5 justify-center'>
                                            <button className='btn btn-warning bg-green-500 text-white' onClick={() => setMode({ mode: 'editCategory', id: category._id })}>Edit</button>
                                            <button className='btn btn-warning bg-red-500 text-white' onClick={() => setDeleteModal({ show: true, id: category._id })}>Delete</button>
                                        </div>
                                        {deleteModal && deleteModal.show && deleteModal.id === category._id &&
                                            <div className='fixed z-50 w-full h-full top-0 left-0 flex justify-center items-center backdrop-blur-sm'>
                                                <div className='flex flex-col bg-gray-100 shadow-xl dark:bg-slate-800 p-5 rounded-md'>
                                                    <h1 className='font-bold text-3xl'>Are you sure you want to delete this?</h1>
                                                    <div className='flex justify-end gap-5 mt-5'>
                                                        <button
                                                            className='btn btn-warning bg-red-500 text-white'
                                                            onClick={() => setDeleteModal({ show: false, id: '' })}
                                                            disabled={loading}
                                                        >No</button>
                                                        <button
                                                            className='btn btn-success bg-green-500 text-white'
                                                            onClick={() => deleteCategory(deleteModal.id)}
                                                            disabled={loading}
                                                        >Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            )) : <tr className='text-center dark:bg-secondary'> <td colSpan='4' className='dark:bg-secondary'>No categories found</td> </tr>}
                        </tbody>
                    </table>
                    <div className="flex justify-end items-center gap-1 mt-5">
                        <p className="join-item text-black dark:text-white">Showing {startIndex + 1} - {endIndex} of {categories?.length} categories -</p>
                        <p className="join-item text-black dark:text-white">on page {currentPage} of {Math.ceil(categories?.length / itemsPerPage)}</p>
                    </div>
                    <div className='flex justify-end gap-5 mt-5'>
                        <button
                            className='btn btn-warning bg-orange-500 text-white'
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >Previous</button>
                        <button
                            className='btn btn-warning bg-green-500 text-white'
                            onClick={() => setCurrentPage((prev) => (prev + 1))}
                            disabled={categories?.length < itemsPerPage}
                        >Next</button>
                    </div>
                </div>
            }


            {mode.mode === 'add' && <ItemForm setMode={setMode} categories={categories} mode={mode} getItems={getItems} />}
            {mode.mode === 'edit' && <ItemForm setMode={setMode} categories={categories} mode={mode} getItems={getItems} />}
            {mode.mode === 'addCategory' && <CategoryForm setMode={setMode} mode={mode} getCategories={getCategories} />}
            {mode.mode === 'editCategory' && <CategoryForm setMode={setMode} mode={mode} getCategories={getCategories} />}

        </div>
    )
}

export default AffiliatedItems