import React, { useEffect, useState } from "react";
import MyModal from "./MyModal";
import { useForm } from "react-hook-form";
import axios from "axios";
import { auth } from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const AddProfileModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const [mobileNumber, setMobileNumber] = useState(user?.phoneNumber || "");
  // const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient(); // Get the queryClient
  console.log(mobileNumber);
  const [indianStatesData, setIndianStatesData] = useState([]);
  useEffect(() => {
    fetch("jsons/states.json")
      .then((res) => res.json())
      .then((data) => setIndianStatesData(data));
  }, []);

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm();
  // const onSubmit = async (data) => {
  //   const address = {
  //     deliveryName: data?.deliveryName,
  //     deliveryNumber: data?.deliveryNumber,
  //     houseNumber: data?.houseNumber,
  //     roadName: data?.roadName,
  //     cityName: data?.cityName,
  //     districtName: data?.districtName,
  //     stateName: data?.stateName,
  //     pinCode: data?.pinCode,
  //   };
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users`, {
  //       ...data,
  //       uid: user?.uid,
  //       address: [address],
  //     });
  //     if(res.status === 201){
  //       toast("User Information added")
  //     } else{
  //       toast("Error occured. Please try again.")
  //     }
  //   } catch (error) {
  //     console.error("Error adding users:", error);
  //   }finally {
  //     // Set loading state to false after submission
  //     setIsLoading(false);
  //   }
  // };
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Define the mutation function
  const addProfile = async (data) => {
    const address = {
      deliveryName: data?.deliveryName,
      deliveryNumber: data?.deliveryNumber,
      houseNumber: data?.houseNumber,
      roadName: data?.roadName,
      cityName: data?.cityName,
      districtName: data?.districtName,
      stateName: data?.stateName,
      pinCode: data?.pinCode,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users`,
        {
          ...data,
          uid: user?.uid,
          address: [address],
        }
      );

      if (res.status === 201) {
        toast("User Information added");
      } else {
        toast("Error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error adding users:", error);
      throw error;
    }
  };

  // Use useMutation to execute the mutation function
  const mutation = useMutation(addProfile, {
    onSuccess: () => {
      // Invalidate the profile data query to trigger a refetch
      queryClient.invalidateQueries(["userProfile", user?.uid]);
    },
  });

  const onSubmit = (data) => {
    // Call the mutation function when the form is submitted
    mutation.mutate(data);
  };

  return (
    <MyModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Required Information"}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="space-y-3"
      >
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("Enter Your Name")}
            id="name"
            {...register("name")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            value={localStorage.getItem("user-mobile") || ""}
            className="input input-bordered w-full bg-white dark:bg-black"
            {...register("mobileNumber")}
            onChange={(e) => {
              // Update the user's phone number in state
              setMobileNumber(e.target.value);
            }}
            required
          />
        </div>
        <input
          className="input input-bordered w-full bg-white dark:bg-black"
          type="text"
          placeholder={t("Name of the Person to Pick the Delivery")}
          id="deliveryName*"
          {...register("deliveryName")}
          required
        />
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="number"
            placeholder={t("Delivery Mobile Number")}
            id="deliveryNumber"
            {...register("deliveryNumber", {
              required: "This field is required",
              pattern: {
                value: /^\d{10}$/, // Regex pattern for 10-digit number
                message: t("Please enter a valid 10-digit number"),
              },
            })}
          />
          {errors.deliveryNumber && (
            <span className="text-red-500">
              {errors.deliveryNumber.message}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("House No, Flat No")}
            id="houseNumber"
            {...register("houseNumber")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("Road Name, Area, Village")}
            id="roadName"
            {...register("roadName")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("City/Town")}
            id="cityName"
            {...register("cityName")}
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("District")}
            id="districtName"
            {...register("districtName")}
            required
          />
        </div>
        <div className="flex flex-col gap-3 my-4">
          <select
            className="input input-bordered w-full bg-white dark:bg-black"
            id="stateName"
            {...register("stateName")}
            required
          >
            <option value="" disabled selected>
              {t("Select State")}
            </option>
            {indianStatesData.map((state) => (
              <option key={state?.code}>{state?.name}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <input
            className="input input-bordered w-full bg-white dark:bg-black"
            type="text"
            placeholder={t("Enter Pincode")}
            id="pinCode"
            {...register("pinCode")}
            required
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="btn btn-info bg-blue-200 text-black btn-sm"
            onClick={() => reset()}
          >
            {t("Reset")}
          </button>
          <button
            type="submit"
            className="btn btn-warning bg-orange-500 text-white btn-sm"
            disabled={mutation.isLoading} // Disable the button when loading
          >
            {mutation.isLoading ? "Loading..." : t("Save Information")}
          </button>
        </div>
      </form>
    </MyModal>
  );
};

export default AddProfileModal;
