import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import HomeCategoryCard from "./HomeCategoryCard";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import { AiOutlinePause } from "react-icons/ai";
import Loader from "../../Shared/Loader";
import { useTranslation } from "react-i18next";

const HomeCategory = () => {
  const {t} = useTranslation();
  const url = `${process.env.REACT_APP_BACKEND_URL}/category`;

  const { data: categories, isLoading } = useQuery([`categories`], () =>
    axios.get(url).then((data) => {
      return data.data;
    })
  );

  return (
    <div className="space-y-7 text-[#444444] dark:text-white">
      <div className="flex items-center gap-2 lg:gap-4 font-[inter] text-xl">
        <AiOutlinePause className="rotate-90 w-6 h-6" />
        <p className="text-sm lg:text-xl font-semibold">{t('All Categories')}</p>
        <span className="text-[#CCD1DC] dark:text-[#BABABA] text-sm lg:text-xl">
          |
        </span>
        <Link
          to="/categories"
          className="flex items-center gap-1 lg:gap-3 text-xs lg:text-base dark:text-[#BABABA]"
        >
          {t('See All')}
          <HiOutlineArrowRight />
        </Link>
      </div>
      {
        isLoading ? <Loader></Loader> : (
          <div className="grid grid-cols-3 xs:grid-cols-6 sm:grid-cols-6 mx-auto gap-2 xs:gap-1 sm:gap-3">
        {categories?.slice(0, 6).map((category, index) => (
          <HomeCategoryCard key={index} category={category}></HomeCategoryCard>
        ))}
      </div>
        )
      }
    </div>
  );
};

export default HomeCategory;
