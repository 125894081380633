import { FacebookShareButton, TelegramShareButton, TwitterShareButton,WhatsappShareButton } from "react-share";
import { FaFacebook, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa";

const ShareButtons = ({url, message}) => {
    return (
        <div className="flex justify-start items-center gap-4">
            <FacebookShareButton url={url} quote={message}>
                <button><FaFacebook className="text-3xl text-blue-600"></FaFacebook></button>
            </FacebookShareButton>
            <WhatsappShareButton url={url} title={message}>
                <button><FaWhatsapp  className="text-3xl text-green-600"></FaWhatsapp></button>
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={message}>
                <button><FaTelegram className="text-3xl text-blue-500"></FaTelegram></button>
            </TelegramShareButton>
            <TwitterShareButton url={url} title={message}>
                <button><FaTwitter className="text-3xl text-blue-600"></FaTwitter></button>
            </TwitterShareButton>
        </div>
    );
};

export default ShareButtons;