import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.init";

const useProfile = () => {
  const [user] = useAuthState(auth)

  const {
    data: profileData,
    isLoading,
    refetch,
  } = useQuery(
    ["userProfile", user?.uid], // Make sure this matches the key used in invalidateQueries
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/users/userProfile/${user?.uid}`
      );
      return response.data;
    }
  );

  return [profileData, isLoading, refetch]
};

export default useProfile;
