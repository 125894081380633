import React, { useEffect } from "react";
import { useRef } from "react";

const BharatAds = () => {
  //   useEffect(() => {
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   }, []);

  const adsbygoogleRef = useRef(false);

  useEffect(() => {
    // Check if the useEffect has already been called
    if (!adsbygoogleRef.current) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      // Set the ref to true to prevent further executions
      adsbygoogleRef.current = true;
    }
  }, []);

  return (
    <div>
      <ins
        class="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-client="ca-pub-7950328943419082"
        data-ad-slot="5993671129"
        data-ad-format="auto"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins>
    </div>
  );
};

export default BharatAds;
