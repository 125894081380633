import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineLocationOn } from "react-icons/md";
import { VscHeart, VscHeartFilled } from "react-icons/vsc";
import { BsThreeDotsVertical, BsChatLeftDots } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { IoShareSocialOutline } from "react-icons/io5";
import { ImCheckmark2 } from "react-icons/im";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { TbCircleDashed } from "react-icons/tb";
import { useQuery } from "@tanstack/react-query";
import DeleteItemModel from "../Modals/DeleteItemModal";
import useGetLocationName from "../../Hooks/useGetLocationName";
// import { useAuthState } from "react-firebase-hooks/auth";
// import auth from "../../firebase.init";
import { toast } from "react-toastify";
import Loading from "../Shared/Loading";
import LinkShareModal from "../Modals/LinkShareModal";
import SubscriptionModal from "../Modals/SubscriptionModal";

const SingleItemDetails = () => {
  // const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [showShareModal, setShowShareModal] = useState(false);
  const [statusAdded, setStatusAdded] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  // const [premiumContent, setPremiumContent] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  showShareModal || deleteItem !== null
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const url = `${process.env.REACT_APP_ENDPOINT}/item/${itemId}`;

  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const {
    data: itemDetails,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery([`itemDetails`, url, config], () =>
    axios
      .get(url, config)
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        // if (err?.response.status === 401) {
        //   setShowSubscriptionModal(true);
        //   toast.error(`Not Authorized to view Premium Content`, {
        //     theme: "colored",
        //   });
        // }
      })
  );

  const [favourite, setFavourite] = useState(false);
  useEffect(() => {
    setFavourite(itemDetails?.isFavorited);
  }, [itemDetails?.isFavorited]);

  //GetLocationData
  const coordinates = itemDetails?.location?.coordinates;
  const locationData = useGetLocationName(coordinates?.[1], coordinates?.[0]);

  //HandlePathname
  // let pathname;
  // if (localStorage.getItem("user-id") === itemDetails?.seller?._id) {
  //   pathname = `/my-profile/${user?.displayName?.split(" ").join("_")}`;
  // } else {
  //   pathname = `/profile/${itemDetails?.seller?.name?.split(" ").join("_")}`;
  // }

  //Functions of the Slider of the Images

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <HiArrowNarrowLeft
        className={className}
        style={{
          ...style,
          color:
            localStorage.getItem("theme") === "light" ? "#697798" : "white",
          backgroundColor:
            localStorage.getItem("theme") === "light" ? "#C1C8D8" : "#1B1B1B",
          borderRadius: "50%",
          height: "20px",
          width: "20px",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <HiArrowNarrowRight
        className={className}
        style={{
          ...style,
          color:
            localStorage.getItem("theme") === "light" ? "#697798" : "white",
          backgroundColor:
            localStorage.getItem("theme") === "light" ? "#C1C8D8" : "#1B1B1B",
          borderRadius: "50%",
          height: "20px",
          width: "20px",
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  //Handle Favourite Icon
  const handleFavourite = () => {
    if (!localStorage.getItem("firebase-user-id")) {
      toast.error("You need to Login First", {
        theme: "colored",
      });
      navigate(`/login`);
    } else {
      setLoading(true);
      const url = `${process.env.REACT_APP_ENDPOINT}/item/toggle-favourite/${itemId}`;
      axios
        .post(url, {}, config)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.indexOf(itemId) !== -1) {
              setFavourite(true);
              toast.success(
                `${itemDetails?.itemName} is Added to the favourite`,
                {
                  theme: "colored",
                }
              );
            } else {
              setFavourite(false);
              toast.error(
                `${itemDetails?.itemName} is removed from favourite`,
                {
                  theme: "colored",
                }
              );
            }
          } else {
            toast.error(`Can't Add ${itemDetails?.itemName} as favourite`, {
              theme: "colored",
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  //Handle Chat
  const handleChat = () => {
    if (!localStorage.getItem("firebase-user-id")) {
      navigate(`/login`);
      toast.error("You need to Login First", {
        theme: "colored",
      });
    } else {
      setLoading(true);
      const url = `${process.env.REACT_APP_ENDPOINT}/chats`;
      const body = {
        users: [localStorage.getItem("user-id"), itemDetails?.seller?._id],
        post: itemId,
      };
      axios
        .post(url, body)
        .then((data) => {
          if (data.status === 201 || 200) {
            navigate(`/inbox/${data?.data?._id}`);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleMakeStatus = (userId) => {
    setLoading(true);
    const statusURL = `${process.env.REACT_APP_ENDPOINT}/status/${userId}`;
    const body = { product: itemId };
    axios
      .post(statusURL, body, itemDetails?.status)
      .then((data) => {
        if (data.status === 201 || 200) {
          toast.success("Status is uploaded Successfully", {
            theme: "colored",
          });
          setStatusAdded(true);
        }
      })
      .finally(() => setLoading(false));
  };
  if (isLoading || !isSuccess || loading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        Home / {itemDetails?.category?.name} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {itemDetails?.itemName}
        </span>
      </p>
      <div className="card shadow-xl bg-white dark:bg-secondary mt-6">
        <div className="flex justify-between p-3 xs:p-6">
          <div className="flex items-center gap-3">
            <div className="avatar">
              <div className="w-8 xs:w-12 rounded-full">
                <img
                  src={
                    itemDetails?.seller?.image ||
                    "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                  }
                  alt=" "
                  onClick={() =>
                    navigate(`/profile/${itemDetails?.seller?._id}`, {
                      state: itemDetails?.seller,
                    })
                  }
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="text-xs xs:text-sm font-[poppins]">
              <p>{itemDetails?.seller?.name}</p>
              <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A]">
                Member{" "}
                {itemDetails?.seller?.createdAt
                  ? moment(itemDetails?.seller?.createdAt).format("YYYY")
                  : "2023"}
              </span>
            </div>
          </div>
          {localStorage.getItem("user-id") === itemDetails?.seller?._id && (
            <div className="flex items-center sm:gap-3">
              {statusAdded ||
                (new Date(itemDetails?.statusExpiry) < new Date() && (
                  <div
                    onClick={() =>
                      handleMakeStatus(localStorage.getItem("user-id"))
                    }
                    className="text-[#444444] dark:text-white bg-[#F6F6F6] dark:bg-[#2A2A2A] h-6 w-20 xs:h-8 xs:w-[133px] rounded-lg flex justify-center items-center gap-2 cursor-pointer"
                  >
                    <TbCircleDashed className="h-4 w-4 xs:h-5 xs:w-5" />
                    <span className="font-[inter] text-[8px] xs:text-base">
                      Add Status
                    </span>
                  </div>
                ))}

              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost capitalize">
                  <div className="flex items-center justify-center gap-2">
                    <BsThreeDotsVertical className="h-3 w-3 xs:h-6 xs:w-6 text-[#949494]" />
                  </div>
                </label>
                <ul
                  tabIndex={0}
                  className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white dark:bg-secondary rounded-box w-40 xs:w-52"
                >
                  <li>
                    <Link
                      to={`/edit-item/${itemDetails?._id}`}
                      state={itemDetails}
                    >
                      <BiEditAlt /> Edit
                    </Link>
                  </li>
                  <li>
                    <label
                      htmlFor="delete-item-modal"
                      onClick={() => {
                        setDeleteItem(itemDetails);
                      }}
                    >
                      <AiOutlineDelete /> Delete
                    </label>
                  </li>
                  <li>
                    <a href=" ">
                      <ImCheckmark2 /> Mark as sold
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="bg-[#F6F6F6] dark:bg-[#272727]">
          <div className="max-w-xl mx-auto h-[100px] xs:h-[180px] sm:h-[250px] md:h-[365px]">
            <Slider {...settings}>
              {itemDetails?.imageGallery?.map((img, index) => (
                <div key={index}>
                  <img
                    src={process.env.REACT_APP_BACKEND_URL + '/' + img}
                    // src={img}
                    alt="Post"
                    className="h-[100px] xs:h-[180px] sm:h-[250px] md:h-[365px] mx-auto object-cover"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="card-body p-3 xs:p-8">
          <div className="flex justify-between">
            <div className="flex flex-col xs:flex-row gap-2 xs:gap-7">
              {localStorage.getItem("user-id") !== itemDetails?.seller?._id && (
                <button
                  onClick={handleChat}
                  className="flex items-center xs:items-start gap-2"
                >
                  <BsChatLeftDots className="h-3 w-3 xs:h-5 xs:w-5" />
                  <span className="text-xs xs:text-sm">Chat</span>
                </button>
              )}

              <button
                onClick={() => setShowShareModal(true)}
                className="flex items-center xs:items-start gap-2"
              >
                <IoShareSocialOutline className="h-3 w-3 xs:h-5 xs:w-5" />
                <span className="text-xs xs:text-sm">Share</span>
              </button>
            </div>
            <div>
              <div className="flex items-center gap-[2px] xs:gap-2">
                <MdOutlineLocationOn className="h-3 w-3 xs:w-5 xs:h-5" />
                <span className="text-[10px] xs:text-sm font-medium">
                  {`${locationData?.city}, ${locationData?.state}`}
                </span>
              </div>
              <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A] ml-2 xs:ml-1">
                {moment(itemDetails?.datePosted).format("DD MMMM YYYY")}
              </span>
            </div>
          </div>
          <h2 className="text-base xs:text-2xl font-semibold">
            {itemDetails?.itemName}
          </h2>
          <h2 className="text-base xs:text-2xl font-semibold">
            ₹ {itemDetails?.discPrice}
          </h2>
          <div>
            {itemDetails?.brand && (
              <>
                <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A]">
                  Brand
                </span>
                <p className="text-xs xs:text-base">{itemDetails?.brand}</p>
              </>
            )}
          </div>
          <div className="flex flex-col xs:flex-row xs:justify-between gap-4">
            <div>
              <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A]">
                Description
              </span>
              <p className="text-xs xs:text-base">{itemDetails?.description}</p>
            </div>
            <div className="flex gap-2 items-center justify-end">
              {/* {localStorage.getItem("firebase-user-id") && ( */}
              <button
                onClick={handleFavourite}
                className="bg-[#F6F6F6] dark:bg-[#232323] h-[30px] w-[30px] xs:h-[50px] xs:w-[50px] flex justify-center items-center rounded-lg xs:rounded-xl"
              >
                {favourite ? (
                  <VscHeartFilled className="w-4 h-4 xs:w-7 xs:h-7" />
                ) : (
                  <VscHeart className="w-4 h-4 xs:w-7 xs:h-7" />
                )}
              </button>
              {/* )} */}
              <button className="text-white bg-[#121212] h-[30px] w-[100px] xs:h-[50px] xs:w-[177px] flex justify-center items-center rounded-lg xs:rounded-xl text-sm xs:text-lg">
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {deleteItem && (
        <DeleteItemModel
          deleteItem={deleteItem}
          setDeleteItem={setDeleteItem}
          refetch={refetch}
        />
      )}
      <LinkShareModal
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        name={itemDetails?.itemName}
      />
      <SubscriptionModal
        showSubscriptionModal={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
      />
    </div>
  );
};

export default SingleItemDetails;
