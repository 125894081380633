// Import the functions you need from the SDKs you need
// // Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";



// Your web app's Firebase configuration
// old firebaseConfig
// const firebaseConfig = {
//   apiKey: "AIzaSyD0MGbNCq1V1xLiSDPiX066Oy3g82Th9xk",
//   authDomain: "bharat-9888d.firebaseapp.com",
//   projectId: "bharat-9888d",
//   storageBucket: "bharat-9888d.appspot.com",
//   messagingSenderId: "145619415774",
//   appId: "1:145619415774:web:2f4b2d7d892929f360c84f"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAvfVFCysqg5VN2ZLk2NALJ0rqwm6Bjpos",
  authDomain: "bharat-web-9de87.firebaseapp.com",
  projectId: "bharat-web-9de87",
  storageBucket: "bharat-web-9de87.appspot.com",
  messagingSenderId: "112595789904",
  appId: "1:112595789904:web:abebf3e769968852b38955",
  measurementId: "G-PBR3PTRE3S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);





// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

// // Initialize Firebase
// // const analytics = getAnalytics(app);
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// export const storage = getStorage(app);
// export default auth;
