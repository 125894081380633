import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useProfile from "./useProfile";

const useCart = () => {
  const [profileData] = useProfile();

  const {
    data: carts,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ["carts", profileData?.mobileNumber],
    queryFn: async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/carts/${profileData?.mobileNumber}`
      );
      return res.data;
    },
  });

  return [carts, refetch, isLoading ]
};

export default useCart;
