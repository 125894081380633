import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useScrollTop from "../../../Hooks/useScrollTop";
import Loading from "../../Shared/Loading";

const AllOrders = () => {
  const { t } = useTranslation();
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const url = `${process.env.REACT_APP_BACKEND_URL}/orders/all-orders`;

  const { data: allOrders, isLoading } = useQuery({
    queryKey: ["all-orders"],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data;
    },
  });
  console.log(allOrders);

  const sortedOrders = allOrders?.sort((a, b) => {
    return new Date(b.orderDate) - new Date(a.orderDate);
  });

  const dataToRender = sortedOrders?.slice(startIndex, endIndex);

  return (
    <div className="pb-8 min-h-screen">
      <h1 className="text-4xl dark:text-white text-black font-bold">All Orders : {allOrders?.length}</h1>

      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="overflow-x-auto mt-8 pr-4">
          <table className="table w-full table-xs">
            <thead>
              <tr>
                <th className="dark:bg-secondary">#</th>
                <th className="dark:bg-secondary">{t("Item Name")}</th>
                <th className="dark:bg-secondary">{t("Total Amount")}</th>
                <th className="dark:bg-secondary">{t("Customer Name")}</th>
                <th className="dark:bg-secondary">{t("Billing User Name")}</th>
                <th className="dark:bg-secondary">
                  {t("Ordered Date & Time")}
                </th>
                <th className="dark:bg-secondary">{t("Transaction Id")}</th>
              </tr>
            </thead>
            <tbody>
              {dataToRender?.map((order, index) => (
                <tr key={startIndex + index + 1}>
                  <td className="dark:bg-secondary">{startIndex + index + 1}</td>
                  {/* <td className="dark:bg-secondary" style={{ whiteSpace: 'pre-line' }}>{truncateText(order.itemName, 100)}</td> */}
                  <td
                    className="dark:bg-secondary"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {order.items
                      .map((item) => `${item.itemName} (${item.quantity})`)
                      .join(", ")}
                  </td>
                  <td className="dark:bg-secondary">  ₹{order.amount + (order.deliveryFee ? parseFloat(order.deliveryFee) : 0)}</td>
                  <td className="dark:bg-secondary">{order.userName}</td>
                  <td className="dark:bg-secondary">{order.pickupPerson}</td>
                  <td className="dark:bg-secondary">
                    {moment(order.orderDate).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td className="dark:bg-secondary">
                    {order.transactionId ? order.transactionId : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-end items-center gap-1 mt-5">
        <p className="join-item text-black dark:text-white">Showing {startIndex + 1} - {endIndex} of {sortedOrders?.length} orders -</p>
        <p className="join-item text-black dark:text-white">on page {currentPage} of {Math.ceil(sortedOrders?.length / itemsPerPage)}</p>
      </div>
      <div className="join grid gap-2 grid-cols-2 items-center rounded-md w-1/2 md:w-1/4 lg:w-1/6 ml-auto mt-5 pagination">
        <button
          className="join-item btn btn-warning text-white bg-orange-500"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous page
        </button>
        <button
          className="join-item btn btn-success bg-green-500 text-white"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={endIndex >= sortedOrders?.length}
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default AllOrders;
