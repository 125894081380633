import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const RefundPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Refund Policy</title>
      </Helmet>
      <div className="p-8 bg-orange-500">
        <h1 className="text-center font-semibold text-3xl text-white">
          {t("Refund Policy")}
        </h1>
      </div>
      <div className="px-8 mt-4 text-justify dark:text-white text-gray-500">
        <div>
          <p>
            {t(
              "1. If the product is reached you in bad condition please inform us immediately over phone (9535601936) and send the picture to our whatsapp number (9535601936) and send the product to us within three days. Either we will send a new product or refund the amount paid for the particular product with the return courier/postal charges."
            )}
          </p>
          <br />
          <p>
            {" "}
            {t(
              "2. Any of the order placed with Bharat Calendar Bhakthi Shop will be subject to cancelation before shipping. Any cancelation request should reach us before the shipping of the products. If the order was cancelled before shipment the refund will be 100%. Any refund request will get processed with in a day of accepting the cancelation policy. We will usually refund any money received from you using the same method originally used by you to pay for your purchase. We will process the refund due to you as soon as possible and, in any event, within 10 days of the day we received your notice of cancellation."
            )}
          </p>
          <br />
          <p>
            {t(
              "3. If we are unable to deliver because of wrong address, receiver unavailability then the responsibility is on the receiver side"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
