import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from '../../Shared/Loading'
import { IoArrowBack } from 'react-icons/io5'

const ItemForm = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const id = useParams().id

    const [mode] = useState(location.pathname.includes('addItem') ? 'add' : 'edit')

    const [formData, setFormData] = useState({
        category: '',
        itemName: '',
        discPrice: '',
        price: '',
        quantities: '',
        tab1: '',
        tab2: '',
        tab3: '',
        description: '',
        about: '',
        deals: false,
        position: 0,
        imageGallery: [],
    })
    const imageRefs = useRef([])
    const [exisitingImages, setExistingImages] = useState([])
    const [selectedPos, setSelectedPos] = useState(mode === 'edit' ? ' ' : 'down');
    const [loading, setLoading] = useState(false)
    const [lang, setLang] = useState('en')

    const url = `${process.env.REACT_APP_BACKEND_URL}/category`;

    const { data: categories, isLoading } = useQuery([`categories`], () =>
        axios.get(url).then((data) => {
            console.log(data.data)
            return data.data;
        })
    );

    useEffect(() => {
        const fetchSignelItem = async () => {
            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/items/${id}?lang=${lang}`

                await axios.get(url).then((data) => {
                    const findCategory = categories?.find((category) => category.name === data.data.category)
                    setFormData({
                        category: findCategory._id,
                        itemName: data.data.itemName,
                        discPrice: data.data.discPrice,
                        price: data.data.price,
                        quantities: data.data.quantities,
                        tab1: data.data.tab1,
                        tab2: data.data.tab2,
                        tab3: data.data.tab3,
                        position: data.data.position,
                        description: data.data.description,
                        about: data.data.about,
                        deals: data.data.deals,
                        imageGallery: [],
                    })
                    setExistingImages(data.data.imageGallery.map((img) => `${process.env.REACT_APP_BACKEND_URL}/${img}`))
                    console.log(formData)
                })
            } catch (err) {
                console.log(err)
            }
        }

        if (id && mode === 'edit') {
            fetchSignelItem()
        }
    }, [lang, id, mode, categories])

    const handlePositionChange = (e) => {
        setSelectedPos(e.target.value)
    }

    const renderAddForm = () => {

        const handleFileChanges = (e) => {
            const files = e.target.files;
            if (files.length === 1) {
                setFormData(prevFormData => ({ ...prevFormData, imageGallery: [...prevFormData.imageGallery, files[0]] }));
                imageRefs.current = [...imageRefs.current, React.createRef()];
            } else {
                setFormData(prevFormData => ({ ...prevFormData, imageGallery: [...prevFormData.imageGallery, ...files] }));
                imageRefs.current = [...imageRefs.current, ...Array.from(files).map(() => React.createRef())];
            }
        };

        const handleRemoveImage = (index) => {
            setFormData(prevFormData => ({ ...prevFormData, imageGallery: prevFormData.imageGallery.filter((img, i) => i !== index) }));

            imageRefs.current = imageRefs.current.filter((ref, i) => i !== index);

            const fileInput = document.getElementById("imageGallery");
            if (fileInput) {
                fileInput.value = null
            }
        };

        const handleSubmit = async (e) => {
            e.preventDefault()
            if (!formData.category || !formData.itemName || !formData.price || !formData.quantities || !formData.description ||
                !formData.imageGallery.length || !formData.discPrice || !formData.tab1 || !formData.tab2 || !formData.tab3) {
                return toast.error('All fields are required')
            }

            const findCategoryName = categories.find((category) => category._id === formData.category)

            const form = new FormData()
            form.append('category', findCategoryName.name)
            form.append('itemName', formData.itemName)
            form.append('discPrice', formData.discPrice)
            form.append('price', formData.price)
            form.append('quantities', formData.quantities)
            form.append('tab1', formData.tab1)
            form.append('tab2', formData.tab2)
            form.append('tab3', formData.tab3)
            form.append('description', formData.description)
            form.append('about', formData.about)
            form.append('deals', formData.deals)

            if (selectedPos === 'up') {
                form.append('moveTop', true)
            } else if (selectedPos === 'down') {
                form.append('moveDown', true)
            }

            formData.imageGallery.forEach((image) => {
                form.append(`imageGallery`, image);
            });

            try {
                setLoading(true)
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/items/`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (res.status === 200) {
                    toast.success('Item added successfully')
                    setFormData({})
                    setLoading(false)
                    navigate('/adminDashboard/allItems')
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        return (
            <form className='flex flex-col gap-5 w-full h-full'>
                <div className='flex flex-col md:flex-row justify-between gap-5'>
                    <div className='flex flex-col gap-5 w-full'>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Item Name</label>
                                <input
                                    type="text"
                                    value={formData.itemName} onChange={(e) => setFormData({ ...formData, itemName: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Category</label>
                                <select
                                    value={formData.category}
                                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border'
                                >
                                    <option className='text-black' value="">Select Category</option>
                                    {categories?.map((category) => (
                                        <option className='text-black' key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Price</label>
                                <input
                                    type="number"
                                    value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Discount Price</label>
                                <input
                                    type="number"
                                    value={formData.discPrice} onChange={(e) => setFormData({ ...formData, discPrice: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Quantities</label>
                                <input
                                    type="number"
                                    value={formData.quantities} onChange={(e) => setFormData({ ...formData, quantities: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 1</label>
                                <input
                                    type="text"
                                    value={formData.tab1} onChange={(e) => setFormData({ ...formData, tab1: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 2</label>
                                <input
                                    type="text"
                                    value={formData.tab2} onChange={(e) => setFormData({ ...formData, tab2: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 3</label>
                                <input
                                    type="text"
                                    value={formData.tab3} onChange={(e) => setFormData({ ...formData, tab3: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex gap-5 w-full items-center'>
                                <label>Position</label>
                                <input
                                    type='checkbox'
                                    className='w-5 h-5' name='position'
                                    checked={selectedPos === 'up'}
                                    value='up'
                                    onChange={(e) => handlePositionChange(e)}
                                /> Move to Top
                                <input
                                    type='checkbox'
                                    className='w-5 h-5' name='position'
                                    checked={selectedPos === 'down'}
                                    value='down'
                                    onChange={(e) => handlePositionChange(e)}
                                /> Move to Bottom
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Description</label>
                                <textarea
                                    value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>About</label>
                                <textarea
                                    value={formData.about} onChange={(e) => setFormData({ ...formData, about: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                />
                            </div>
                            <div className='flex flex-col gap-5 md:w-1/2 w-full items-center justify-center'>
                                <label>Add for deals?</label>
                                <input
                                    type='checkbox'
                                    value={formData.deals} onChange={(e) => setFormData({ ...formData, deals: e.target.checked })}
                                    className='p-2 rounded-2xl w-10 h-10 bg-transparent border' />
                            </div>
                        </div>

                        <label>Images</label>
                        <label htmlFor='imageGallery' className='btn bg-blue-500 text-white'>{formData.imageGallery.length > 0 ? 'Add More Images' : 'Select Images'}</label>
                        <input
                            id='imageGallery'
                            type="file"
                            onChange={(e) => handleFileChanges(e)}
                            className='p-2 rounded-2xl bg-transparent border hidden'
                            multiple
                            ref={(ref) => imageRefs.current.push(ref)}
                        />
                        <div className='flex w-full gap-5'>
                            {formData.imageGallery.length > 0 && formData.imageGallery.map((image, index) => {
                                return (
                                    <div className='flex flex-col gap-3 w-1/3 h-full' key={index}>
                                        <img src={URL.createObjectURL(image)} alt='main' className='relative h-full' />
                                        <button
                                            onClick={() => handleRemoveImage(index)}
                                            className='btn text-xl btn-error w-full bg-red-500 text-white'>DESELECT IMAGE</button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                    Add Item
                </button>
            </form>
        )
    }

    const renderEditForm = () => {

        const handleFileChanges = (e) => {
            setFormData(prevFormData => ({ ...prevFormData, imageGallery: [...prevFormData.imageGallery, ...e.target.files] }));
            imageRefs.current = [...imageRefs.current, ...Array.from(e.target.files).map(() => React.createRef())];
        };

        const handleRemoveImage = (e, index) => {
            e.preventDefault()
            setFormData(prevFormData => ({ ...prevFormData, imageGallery: prevFormData.imageGallery.filter((img, i) => i !== index) }));

            imageRefs.current = imageRefs.current.filter((ref, i) => i !== index);

            const fileInput = document.getElementById("imageGallery");
            if (fileInput) {
                fileInput.value = null
            }
        };

        const handleRemoveImageDB = async (e, indexToRemove) => {
            e.preventDefault()

            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/items/images/${id}?index=${indexToRemove}`

                const res = await axios.delete(url)
                if (res.status === 200) {
                    toast.success('Image removed successfully')
                    setExistingImages(exisitingImages.filter((img, index) => index !== indexToRemove))
                }
            } catch (err) {
                console.log(err)
                toast.error('Error removing image')
            }
        };

        const handleSubmit = async (e) => {
            e.preventDefault()

            if (!formData.category || !formData.itemName || !formData.price || !formData.quantities || !formData.description ||
                !formData?.imageGallery || !formData.discPrice || !formData.tab1 || !formData.tab2 || !formData.tab3) {
                // show which field is missing
                console.log(formData)
                return toast.error('All fields are required')
            }

            const findCategoryName = categories.find((category) => category._id === formData.category)

            const form = new FormData()
            form.append('category', findCategoryName.name)
            form.append('itemName', formData.itemName)
            form.append('discPrice', formData.discPrice)
            form.append('price', formData.price)
            form.append('quantities', formData.quantities)
            form.append('tab1', formData.tab1)
            form.append('tab2', formData.tab2)
            form.append('tab3', formData.tab3)
            form.append('description', formData.description)
            form.append('about', formData.about)
            form.append('deals', formData.deals)

            if (selectedPos === 'up') {
                form.append('moveTop', true)
            } else if (selectedPos === 'down') {
                form.append('moveDown', true)
            } else {
                form.append('position', formData.position)
            }

            formData.imageGallery.forEach((image) => {
                form.append(`imageGallery`, image);
            });

            try {
                setLoading(true)
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/items/${id}`, form)
                if (res.status === 200) {
                    toast.success('Item updated successfully')
                    setFormData({})
                    setLoading(false)
                    navigate('/adminDashboard/allItems')
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        return (
            <form className='flex flex-col gap-5 w-full h-full'>
                <div className='flex flex-col md:flex-row justify-between gap-5'>
                    <div className='flex flex-col gap-5 w-full'>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Item Name</label>
                                <input
                                    type="text"
                                    value={formData.itemName} onChange={(e) => setFormData({ ...formData, itemName: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Category</label>
                                <select value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })} className='p-2 rounded-2xl bg-transparent border'>
                                    <option className='text-black' value="">Select Category</option>
                                    {categories?.map((category) => (
                                        <option className='text-black' key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Price</label>
                                <input
                                    type="number"
                                    value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Discount Price</label>
                                <input
                                    type="number"
                                    value={formData.discPrice} onChange={(e) => setFormData({ ...formData, discPrice: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Quantities</label>
                                <input
                                    type="number"
                                    value={formData.quantities} onChange={(e) => setFormData({ ...formData, quantities: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 1</label>
                                <input
                                    type="text"
                                    value={formData.tab1} onChange={(e) => setFormData({ ...formData, tab1: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 2</label>
                                <input
                                    type="text"
                                    value={formData.tab2} onChange={(e) => setFormData({ ...formData, tab2: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Tab 3</label>
                                <input
                                    type="text"
                                    value={formData.tab3} onChange={(e) => setFormData({ ...formData, tab3: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border' />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex gap-5 w-full items-center'>
                                <label>Current position: {formData.position}</label>
                                <input
                                    type='checkbox'
                                    className='w-5 h-5' name='position'
                                    checked={selectedPos === 'up'}
                                    value='up'
                                    onChange={(e) => handlePositionChange(e)}
                                /> Move to Top
                                <input
                                    type='checkbox'
                                    className='w-5 h-5' name='position'
                                    checked={selectedPos === 'down'}
                                    value='down'
                                    onChange={(e) => handlePositionChange(e)}
                                /> Move to Bottom
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row justify-between w-full gap-10'>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>Description</label>
                                <textarea
                                    value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                />
                            </div>
                            <div className='flex flex-col gap-5 w-full'>
                                <label>About</label>
                                <textarea
                                    value={formData.about} onChange={(e) => setFormData({ ...formData, about: e.target.value })}
                                    className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                />
                            </div>
                            <div className='flex flex-col gap-5 md:w-1/2 w-full items-center justify-center'>
                                <label>Add for deals?</label>
                                <input
                                    type='checkbox'
                                    checked={formData.deals}
                                    value={formData.deals} onChange={(e) => setFormData({ ...formData, deals: e.target.checked })}
                                    className='p-2 rounded-2xl w-10 h-10 bg-transparent border' />
                            </div>
                        </div>

                        <label>Images</label>
                        <label htmlFor='imageGallery' className='btn bg-blue-500 text-white'>Select Images</label>
                        <input
                            id='imageGallery'
                            type="file"
                            onChange={(e) => handleFileChanges(e)}
                            className='p-2 rounded-2xl bg-transparent border hidden'
                            multiple
                            ref={(ref) => imageRefs.current.push(ref)}
                            maxLength={5}
                        />
                        <div className='flex md:flex-row flex-col gap-5 overflow-x-auto'>
                            {formData?.imageGallery && formData?.imageGallery.map((image, index) => {
                                return (
                                    <div className='flex flex-col gap-3 md:w-1/3 h-full' key={index}>
                                        <img src={URL.createObjectURL(image)} alt='main' className='relative h-full' />
                                        <button
                                            onClick={(e) => handleRemoveImage(e, index)}
                                            className='btn text-xl btn-error w-full bg-red-500 text-white'>DESELECT IMAGE</button>
                                    </div>
                                )
                            })}
                        </div>
                        {exisitingImages.length > 0 &&
                            <div>
                                <label className='text-xl font-bold'>Existing Images</label>
                                <div className='flex md:flex-row mt-5 flex-col w-full gap-5'>
                                    {exisitingImages?.map((image, index) => {
                                        return (
                                            <div className='flex flex-col gap-3 md:w-1/3 h-full' key={index}>
                                                <img src={image} alt='main' className='relative max-h-full max-w-full' />
                                                <button type='button' className='btn btn-error' onClick={(e) => handleRemoveImageDB(e, index)}>Remove from DB</button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                    Save Changes
                </button>
            </form >
        )
    }

    function renderBasedOnMode() {
        switch (mode) {
            case 'add':
                return renderAddForm()
            case 'edit':
                return renderEditForm()
            default:
                return navigate('allItems')
        }
    }

    return (
        <div className='md:p-10 md:pt-0'>
            <button onClick={() => navigate('/adminDashboard/allItems')} className='btn bg-blue-500 text-white'><IoArrowBack className='mr-2 text-xl' />Back</button>
            <div className='flex justify-between items-center px-2 w-full pt-5'>
                <h1 className='font-bold text-3xl'>{mode === 'add' ? 'Add' : 'Edit'} Item</h1>
            </div>
            <div className='flex p-5 mt-5 border rounded-2xl'>
                {renderBasedOnMode()}
            </div>
        </div>
    )
}

export default ItemForm