import React, { useState } from "react";
import { TbShieldLockFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import SubscriptionModal from "../Modals/SubscriptionModal";
const AccessItemDiv = () => {
  const navigate = useNavigate();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  return (
    <div className="App h-[calc(100vh-96px)]">
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-[780px] rounded-xl p-3 sm:p-[30px] mx-auto space-y-10">
        <div className="bg-secondary dark:bg-white h-40 w-40 rounded-2xl flex justify-center items-center mx-auto">
          <TbShieldLockFilled className="h-20 w-20 text-white dark:text-secondary" />
        </div>
        <p className="font-[inter] text-2xl font-semibold text-center px-48">
          You don't have access to this premium content
        </p>
        <div className="flex justify-center gap-5">
          <button
            onClick={() => navigate("/")}
            className="btn btn-ghost font-bold w-44 border border-secondary dark:border-white"
          >
            Back to Home
          </button>
          <button
            className="btn btn-warning font-bold w-44"
            onClick={() => setShowSubscriptionModal(true)}
          >
            Subscribe
          </button>
        </div>
      </div>
      <SubscriptionModal
        showSubscriptionModal={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
      />
    </div>
  );
};

export default AccessItemDiv;
