import React from "react";
import MyModal from "./MyModal";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase.init";

const NotifyModal = ({ isOpen, setIsOpen, item, setNotificationSent}) => {
  const [user] = useAuthState(auth);

  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/notifications`, data)
    .then(res => {
      console.log(res.data);
      toast("Notified Successfully.")
      localStorage.setItem(`notification-${item._id}`, "sent");
      setNotificationSent(true); 
    })
  }


  return (
    <MyModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Notify (Out of Stock)"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="hidden">
          <label>Item ID:</label>
          <input
            type="text"
            {...register("itemId", { required: true })}
            placeholder="*Item ID"
            value={item?._id}
            className="input input-bordered w-full max-w-md"
          />
        </div>
        <div>
          <label>Mobile Number:</label>
          <input
            type="text"
            {...register("userMobileNumber", { required: true })}
            placeholder="*Mobile Number"
            value={user?.phoneNumber}
            className="input input-bordered w-full max-w-md"
          />
        </div>
        <div>
          <label>Item Name:</label>
          <input
            type="text"
            {...register("itemName", { required: true })}
            placeholder="*Item Name"
            value={item?.itemName}
            className="input input-bordered w-full max-w-md"
          />
        </div>
        <div className="w-9/12 mx-auto mt-5">
          <button onClick={() => setIsOpen(!isOpen)} className="btn btn-block text-white btn-warning bg-[#c58b1f]">
            Send Notification
          </button>
        </div>
      </form>
    </MyModal>
  );
};

export default NotifyModal;
