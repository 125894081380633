import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Loading = () => {

  return (
    // <div className="sweet-loading h-[calc(100vh-96px)]   backdrop-blur-sm">
    <div className="sweet-loading h-[calc(100vh-96px)] flex justify-center inset-0 z-50 items-center ">
      <ThreeDots
        height="80" 
        width="80" 
        radius="9"
        color="#4fa94d" 
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default Loading;
