import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../../Assets/logo/Bharat.jpg";
import logo2 from "../../Assets/logo/Bharat.png";
import { useTranslation } from "react-i18next";

const Footer = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <div>
      <footer className="footer p-10 py-16 mt-8 bg-orange-500 dark:bg-black text-white">
        <aside>
          <div>
            <Link
              to={"/"}
              className="w-32 md:w-36 rounded-md normal-case text-xl"
            >
              <img
                src={theme === "dark" ? logo2 : logo1}
                height={"150px"}
                width={"150px"}
                alt="logo"
                className="rounded-xl"
              />
            </Link>
          </div>
          <p>
            {t("Bharat Calendar Bhakthi Shop")}
            <br />
            {t("Powered By Bharat Calendar")}
          </p>
        </aside>
        <nav>
          <header className="font-bold capitalize text-xl">
            {t("Contact Us")}
          </header>
          <p className="text-lg">{t("Mobile Number")} : 9535601936</p>
        </nav>
        <nav>
          <header className="font-bold capitalize text-xl">
            {t("Address")}
          </header>
          <p className="text-lg">
            {t("D.No. 2- 40, 'Lakshminarayana'")},<br />
            {t("Nayarkodi House, Soorinje Main Road")},<br />
            {t("Katipalla, Mangalore - 575030")}
          </p>
        </nav>
        <nav>
          <header className="font-bold capitalize text-xl">
            {t("Other Links")}
          </header>
          <Link to={"/terms"} className="link link-hover text-lg">
            <p>{t("Terms & Conditions")}</p>
          </Link>
          <Link to={"/privacyPolicy"} className="link link-hover text-lg">
            <p className="ml-[2px]">{t("Privacy Policy")}</p>
          </Link>
        </nav>
      </footer>
    </div>
  );
};

export default Footer;
