import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from '../../Shared/Loading'

const ArticleForm = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const id = useParams().id

    const [formData, setFormData] = useState({
        title: '',
        sections: [
            {
                id: 1,
                heading: '',
                content: ''
            }
        ],
        imageMain: null,
        imageMainExisting: null,
        imageSecondary: null,
        imageSecondaryExisting: null,
        linkImageSecondary: '',
        resetAll: false
    })
    const [loading, setLoading] = useState(false)
    const [lang, setLang] = useState('en')

    const img1ref = useRef()
    const img2ref = useRef()
    const [mode] = useState(location.pathname.includes('addArticle') ? 'add' : 'edit')


    useEffect(() => {
        const FetchSingleArticle = async () => {
            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/articles/single/${id}?lang=${lang}`

                await axios.get(url).then((data) => {
                    setFormData({
                        title: data.data.title,
                        sections: data.data.sections,
                        imageMainExisting: data.data.imageMain || '',
                        imageSecondaryExisting: data.data.imageSecondary || '',
                        linkImageSecondary: data.data.linkImageSecondary || '',
                    })
                })
            } catch (err) {
                console.log(err)
            }
        }

        if (id && mode === 'edit') {
            FetchSingleArticle()
        }
    }, [lang, id, mode])

    const addSection = (e) => {
        e.preventDefault()
        setFormData({ ...formData, sections: [...formData.sections, { id: formData.sections.length + 1, heading: '', content: '' }] })
    }

    const removeSection = (id) => {
        setFormData({ ...formData, sections: formData.sections.filter(section => section.id !== id) })
    }

    // console.log(formData)

    const renderAddForm = () => {
        const handleSubmit = async (e) => {
            e.preventDefault()
            if (!formData.title || !formData.imageMain || !formData.sections[0].heading || !formData.sections[0].content) {
                return toast.error('All fields are required')
            }

            const form = new FormData()
            form.append('title', formData.title)
            form.append('imageMain', formData.imageMain)
            form.append('imageSecondary', formData.imageSecondary)
            form.append('linkImageSecondary', formData.linkImageSecondary)
            form.append('sections', JSON.stringify(formData.sections))
            try {
                setLoading(true)
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/articles/add`, form)
                if (res.status === 200) {
                    toast.success('Article added successfully')
                    setFormData({})
                    img1ref.current.value = ''
                    img2ref.current.value = ''
                    setLoading(false)
                    navigate('/adminDashboard/allArticles')
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        return (
            <form className='flex flex-col gap-5 w-full h-full'>
                <div className='flex flex-col md:flex-row justify-between gap-5'>
                    <div className='flex flex-col gap-5 w-full'>
                        <label>Title</label>
                        <input
                            type="text"
                            value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            className='p-2 rounded-2xl bg-transparent border' />
                        <div className='border rounded-xl flex flex-col h-full w-full p-5 gap-5'>
                            {formData.sections.map((section, index) => (
                                <div className='flex flex-col gap-5' key={index}>
                                    <label>Section Heading</label>
                                    <input
                                        type="text"
                                        value={section.heading}
                                        onChange={(e) => setFormData({ ...formData, sections: formData.sections.map((sec, i) => i === index ? { ...sec, heading: e.target.value } : sec) })}
                                        className='p-2 rounded-2xl bg-transparent border'
                                    />

                                    <label>Section Content</label>
                                    <textarea
                                        value={section.content}
                                        onChange={(e) => setFormData({ ...formData, sections: formData.sections.map((sec, i) => i === index ? { ...sec, content: e.target.value } : sec) })}
                                        className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                    />

                                    <button className='btn btn-error bg-orange-500 text-white' onClick={() => removeSection(section.id)}>
                                        Remove Section
                                    </button>
                                </div>
                            ))}

                            <button className='btn btn-success bg-green-500 text-white' onClick={(e) => addSection(e)}>
                                Add Section
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 w-full md:w-1/2'>
                        <label>Main Image</label>
                        <input
                            type="file"
                            onChange={(e) => setFormData({ ...formData, imageMain: e.target.files[0] })}
                            className='p-2 rounded-2xl bg-transparent border'
                            ref={img1ref} />
                        {formData.imageMain &&
                            <div className='relative'>
                                <img src={URL.createObjectURL(formData.imageMain)} alt='main' className='relative' />
                                <button
                                    onClick={() => {
                                        setFormData({ ...formData, imageMain: null })
                                        img1ref.current.value = ''
                                    }}
                                    className='btn text-xl btn-error btn-circle top-0 right-0 absolute bg-red-500 text-white'>X</button>
                            </div>
                        }
                    </div>
                    <div className='flex flex-col gap-5 w-full md:w-1/2'>
                        <label>Secondary Image</label>
                        <input
                            type="file"
                            onChange={(e) => setFormData({ ...formData, imageSecondary: e.target.files[0] })}
                            className='p-2 rounded-2xl bg-transparent border'
                            ref={img2ref}
                        />
                        {formData.imageSecondary &&
                            <div className='relative'>
                                <img src={URL.createObjectURL(formData.imageSecondary)} alt='secondary' className='relative' />
                                <button
                                    onClick={() => {
                                        setFormData({ ...formData, imageSecondary: null })
                                        img2ref.current.value = ''
                                    }}
                                    className='btn text-xl btn-error btn-circle top-0 right-0 absolute bg-red-500 text-white'>X</button>
                            </div>
                        }
                        <label>Link Image Secondary</label>
                        <input
                            type="text"
                            value={formData.linkImageSecondary} onChange={(e) => setFormData({ ...formData, linkImageSecondary: e.target.value })}
                            className='p-2 rounded-2xl bg-transparent border' />
                    </div>
                </div>
                <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                    Add Article
                </button>
            </form>
        )
    }

    const renderEditForm = () => {
        // console.log('reached')
        const handleSubmit = async (e) => {
            e.preventDefault()

            if (!formData.title || !formData.sections[0].heading || !formData.sections[0].content) {
                return toast.error({
                    message: 'All fields are required',
                    duration: 2500
                })
            }

            const form = new FormData()
            form.append('title', formData.title)
            form.append('imageMain', formData.imageMain)
            form.append('imageSecondary', formData.imageSecondary)
            form.append('linkImageSecondary', formData.linkImageSecondary)
            form.append('sections', JSON.stringify(formData.sections))
            form.append('resetAll', formData.resetAll)

            try {
                setLoading(true)
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/articles/${id}?lang=${lang}`, form)
                if (res.status === 200) {
                    toast.success('Article updated successfully')
                    setFormData({})
                    img1ref.current.value = ''
                    img2ref.current.value = ''
                    setLoading(false)
                    navigate('/adminDashboard/allArticles')
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        return (
            <form className='flex flex-col gap-5 w-full h-full'>
                <div className='flex flex-col md:flex-row justify-between gap-5'>
                    <div className='flex flex-col gap-5 w-full'>
                        <label>Title</label>
                        <input
                            type="text"
                            value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            className='p-2 rounded-2xl bg-transparent border' />
                        <div className='border rounded-xl flex flex-col h-full w-full p-5 gap-5'>
                            {formData.sections.map((section, index) => (
                                <div className='flex flex-col gap-5' key={index}>
                                    <label>Section Heading</label>
                                    <input
                                        type="text"
                                        value={section.heading}
                                        onChange={(e) => setFormData({ ...formData, sections: formData.sections.map((sec, i) => i === index ? { ...sec, heading: e.target.value } : sec) })}
                                        className='p-2 rounded-2xl bg-transparent border'
                                    />

                                    <label>Section Content</label>
                                    <textarea
                                        value={section.content}
                                        onChange={(e) => setFormData({ ...formData, sections: formData.sections.map((sec, i) => i === index ? { ...sec, content: e.target.value } : sec) })}
                                        className='p-2 rounded-2xl bg-transparent border max-h-[300px] h-[500px] overflow-y-auto'
                                    />

                                    <button className='btn btn-error bg-orange-500 text-white' onClick={() => removeSection(section.id)}>
                                        Remove Section
                                    </button>
                                </div>
                            ))}

                            <button className='btn btn-success bg-green-500 text-white' onClick={(e) => addSection(e)}>
                                Add Section
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 w-full md:w-1/2'>
                        <label>Main Image</label>
                        <input
                            type="file"
                            onChange={(e) => setFormData({ ...formData, imageMain: e.target.files[0] })}
                            className='p-2 rounded-2xl bg-transparent border'
                            ref={img1ref} />
                        {formData.imageMain &&
                            <div className='relative'>
                                <label>New Image</label>
                                <img src={URL.createObjectURL(formData.imageMain)} alt='main' className='relative' />
                                <button
                                    onClick={() => {
                                        setFormData({ ...formData, imageMain: null })
                                        img1ref.current.value = ''
                                    }}
                                    className='btn text-xl btn-error btn-circle top-0 right-0 absolute bg-red-500 text-white'>X</button>
                            </div>
                        }
                        {formData.imageMainExisting && <>
                            <label className='mt-5'>Existing Main Image</label>
                            <img src={process.env.REACT_APP_BACKEND_URL + '/' + formData.imageMainExisting} alt='main' className='rounded-2xl' />
                        </>}
                    </div>
                    <div className='flex flex-col gap-5 w-full md:w-1/2'>
                        <label>Secondary Image</label>
                        <input
                            type="file"
                            onChange={(e) => setFormData({ ...formData, imageSecondary: e.target.files[0] })}
                            className='p-2 rounded-2xl bg-transparent border'
                            ref={img2ref}
                        />
                        {formData.imageSecondary &&
                            <div className='relative'>
                                <label>New Image</label>
                                <img src={URL.createObjectURL(formData.imageSecondary)} alt='secondary' className='relative' />
                                <button
                                    onClick={() => {
                                        setFormData({ ...formData, imageSecondary: null })
                                        img2ref.current.value = ''
                                    }}
                                    className='btn text-xl btn-error btn-circle top-0 right-0 absolute bg-red-500 text-white'>X</button>
                            </div>
                        }
                        {formData.imageSecondaryExisting && <>
                            <label className='mt-5'>Existing Secondary Image</label>
                            <img src={process.env.REACT_APP_BACKEND_URL + '/' + formData.imageSecondaryExisting} alt='secondary' className='rounded-2xl' />
                        </>}
                        <label>Link Image Secondary</label>
                        <input
                            type="text"
                            value={formData.linkImageSecondary} onChange={(e) => setFormData({ ...formData, linkImageSecondary: e.target.value })}
                            className='p-2 rounded-2xl bg-transparent border' />
                        <div className=' flex flex-col ml-5 gap-5'>
                            <div className='flex items-center gap-5'>
                                <label>Reset All Translations?</label>
                                <input
                                    type="checkbox"
                                    value={formData.resetAll} onChange={(e) => setFormData({ ...formData, resetAll: e.target.checked })}
                                    className='h-6 w-6  rounded-2xl bg-transparent border' />
                            </div>
                            {formData.resetAll && <p className='text-red-500'>This will reset all translations present in the database for this article and cannot be reversed.</p>}
                        </div>
                    </div>
                </div>
                <button className='btn bg-blue-500 text-white' onClick={(e) => handleSubmit(e)} disabled={loading}>
                    Save Changes
                </button>
            </form>
        )
    }

    function renderBasedOnMode() {
        switch (mode) {
            case 'add':
                return renderAddForm()
            case 'edit':
                return renderEditForm()
            default:
                return navigate('allArticles')
        }
    }

    return (
        <div className='md:p-10'>
            <div className='flex justify-between items-center px-2 w-full'>
                <h1 className='font-bold text-3xl'>{mode === 'add' ? 'Add' : 'Edit'} Article</h1>
                <div className='flex items-center gap-5'>
                    {mode === 'edit' &&
                        <>
                            <label>Change language</label>
                            <select value={lang} onChange={(e) => setLang(e.target.value)} className='p-2 rounded-2xl bg-transparent border'>
                                <option className='bg-black text-white' value="en">English</option>
                                <option className='bg-black text-white' value="mr">Marathi</option>
                                <option className='bg-black text-white' value="hi">Hindi</option>
                                <option className='bg-black text-white' value="ta">Tamil</option>
                                <option className='bg-black text-white' value="te">Telugu</option>
                                <option className='bg-black text-white' value="kn">Kannada</option>
                                <option className='bg-black text-white' value="ml">Malayalam</option>
                                <option className='bg-black text-white' value="bn">Bengali</option>
                                <option className='bg-black text-white' value="gu">Gujarati</option>
                            </select>
                        </>
                    }
                </div>
            </div>
            <div className='flex p-5 mt-5 border rounded-2xl'>
                {renderBasedOnMode()}
            </div>
        </div>
    )
}

export default ArticleForm