import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import ReactGA from 'react-ga4';

const HomeCategoryCard = ({ category }) => {
  const { t } = useTranslation();
  const handleCategoryClick = () => {
    // Track the category click event
    window.gtag('event', 'Click', {
      event_category: 'Category Click',
      event_label: category?.name,
    });
    // ReactGA.event({
    //   category: 'Category Click',
    //   action: 'Click',
    //   label: category.name,
    // });

    // You can also perform the navigation logic here if needed
  };

  return (
    <div>
      <Link to={`/category/${category.name.split(' ').join('-')}`} onClick={handleCategoryClick} >
        <div className="card mx-auto md:w-[90%] lg:w-[80%] bg-white dark:bg-secondary shadow-xl">
          <figure className="p-2">
            <img
              src={category?.logo}
              alt="categorylogo"
              className="rounded-xl"
            />
          </figure>
          <div className="items-center text-center">
            <h2 className="text-xs text-black dark:text-white font-semibold sm:text-sm mb-3 mt-1"> {t(category?.name)}</h2>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HomeCategoryCard;
