import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useAdmin = () => {
    const { data: isAdmin, isLoading: isAdminLoading } = useQuery({
        queryKey: ['isAdmin'],
        queryFn: async () => {
            if (!localStorage.getItem("user-mobile")) {
                return false;
            }
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/admin/${localStorage.getItem("user-mobile")}`);
            return res.data.admin;
        }
    })
    return [isAdmin, isAdminLoading]
}

export default useAdmin;