import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { MdEdit } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loading from "../Shared/Loading";
const SubCategories = () => {
  const navigate = useNavigate();

  const { category } = useLocation().state;

  const url = `${process.env.REACT_APP_ENDPOINT}/collection/subcat/${category?.collection?._id}`;
  const { data: subcategoryData, isLoading } = useQuery(
    [`subcategoryData`],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        Home / {category?.collection?.name} /
        <span className="text-black dark:text-[#E2E2E2]"> Subcategories</span>
      </p>
      <div className="flex items-center justify-between bg-[#EDEFF4] dark:bg-[#3B3B3B] rounded-[10px] border-[#D3DAEB] dark:border-[#6E6E6E] border-[1px] mb-5 px-[18px] h-[70px]">
        <div className="flex gap-[18px] items-center">
          <img
            src={category?.collection?.logo}
            className="w-[40px] md:w-auto"
            alt=" "
          />
          <h1 className="font-semibold">{category?.collection?.name}</h1>
        </div>
        {/* <MdEdit className="h-6 w-6" /> */}
      </div>
      <div
        // className="flex flex-wrap xs:grid xs:grid-cols-2 sm:grid-cols-3 gap-5"
        className="grid grid-cols-2 xs:grid-cols-3 gap-5"
      >
        {subcategoryData?.subcategories?.map((subcategory, index) => (
          <button
            key={index}
            onClick={() => {
              navigate(
                `/${category?.collection?.name.toLowerCase()}/${subcategory?.name
                  .toLowerCase()
                  .split(" ")
                  .join("_")}`,
                { state: { category, subcategory } }
              );
            }}
            // className={`bg-secondary rounded-[10px] border-secondary border-[1px] flex justify-between items-center h-[100px] w-96 p-7`}
            className={`bg-white dark:bg-secondary shadow-md rounded-[10px] dark:border-secondary border-[1px] h-[100px] w-full sm:w-44 md:w-60 md:px-5 mx-auto`}
          >
            <div
              // className="flex items-center gap-4"
              className="flex flex-col md:flex-row md:justify-center items-center gap-2 md:gap-2 w-full"
            >
              <img
                src={subcategory?.logo}
                className="w-[50px] md:w-4/12"
                alt=" "
              />
              <div className="md:w-8/12">
                <h1 className="xs:text-left text-[10px] md:text-base font-[poppins] font-medium mx-[2px] xs:mx-0">
                  {subcategory?.name}
                </h1>
              </div>
            </div>
            {/* {categoryName === category?.name && (
          <CheckIcon className="h-8 w-8" />
        )} */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubCategories;
