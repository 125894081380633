import React from "react";
import useCart from "../../Hooks/useCart";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../Shared/Loading";
import { Link, useLocation } from "react-router-dom";
import useScrollTop from "../../Hooks/useScrollTop";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const MyCart = () => {
  const { t } = useTranslation();
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const [carts, refetch, isLoading] = useCart();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItemsQuantity, setTotalItemsQuantity] = useState(0);
  const [itemQuantities, setItemQuantities] = useState({});

  useEffect(() => {
    if (carts && carts?.length > 0) {
      const newTotalPrice = carts?.reduce(
        (accumulator, cartItem) =>
          accumulator + cartItem?.itemDiscPrice * itemQuantities[cartItem._id],
        0
      );
      setTotalPrice(newTotalPrice);

      const newTotalItemsQuantity = carts?.reduce(
        (accumulator, cartItem) => accumulator + itemQuantities[cartItem._id],
        0
      );
      setTotalItemsQuantity(newTotalItemsQuantity);
    } else {
      setTotalPrice(0);
      setTotalItemsQuantity(0);
    }
  }, [carts, itemQuantities]);

  // Function to update cart item quantity via PUT request
  function updateCartItemQuantity(cartItemId, quantity) {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/carts/${cartItemId}`, {
        quantity: quantity,
      })
      .then((res) => {
        refetch(); // Fetch the updated cart data after successful update
        setItemQuantities((prevQuantities) => ({
          ...prevQuantities,
          [cartItemId]: quantity,
        }));
      })
      .catch((error) => {
        console.error(error);
        // Handle the error as needed
      });
  }

  // Function to handle quantity increase
  const handleQuantityIncrease = (cartItem) => {
    const itemId = cartItem._id;
    const updatedQuantity = itemQuantities[itemId] + 1;
    updateCartItemQuantity(itemId, updatedQuantity);
  };

  // Function to handle quantity decrease
  const handleQuantityDecrease = (cartItem) => {
    const itemId = cartItem._id;
    if (itemQuantities[itemId] > 1) {
      const updatedQuantity = itemQuantities[itemId] - 1;
      updateCartItemQuantity(itemId, updatedQuantity);
    }
  };

  // Separate logic to update itemQuantities
  useEffect(() => {
    if (carts && carts?.length > 0) {
      const initialQuantities = {};
      carts.forEach((cartItem) => {
        initialQuantities[cartItem._id] = cartItem.quantity;
      });
      setItemQuantities(initialQuantities);
    } else {
      setItemQuantities({});
    }
  }, [carts]);

  const handleCartDelete = (id) => {
    const darkModeColor = "black";
    const lightModeColor = "#FFFFFF";
    const swalConfig = {
      title: t("Remove Cart Item?"),
      text: t("Cart will be removed permanently"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: t("Cancel"),
      confirmButtonText: t("Remove"),
      confirmButtonColor: "#db5d14",
    };
    if (localStorage.getItem("theme") === "light") {
      swalConfig.background = lightModeColor;
      swalConfig.color = darkModeColor;
    } else {
      swalConfig.background = darkModeColor;
      swalConfig.color = lightModeColor;
    }

    Swal.fire(swalConfig).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )

        axios
          .delete(`${process.env.REACT_APP_BACKEND_URL}/carts/deleteCart/${id}`)
          .then((res) => {
            console.log(res.data);
            refetch();
          });
      }
    });
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (carts && carts.length === 0) {
    return (
      <div className="min-h-screen">
        <p className="text-xl text-center mt-32 text-orange-500 font-semibold">
          {t("No Items in the cart")}
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Cart</title>
      </Helmet>
      <div className="grid md:grid-cols-3 lg:gap-10 xl:gap-16 sm:p-4 lg:p-6 xl:p-8">
        <div className="md:col-span-2">
          {/* {carts?.map((cart, index) => ( */}
          {carts?.map((cart, index) => (
            <div key={index}>
              <div
                className="flex justify-start items-center mb-4 gap-10 p-10"
                style={{ border: "2px solid orange" }}
              >
                <div>
                  <img src={process.env.REACT_APP_BACKEND_URL+'/'+cart?.itemImage.split('5200')[1]} alt="" className="w-40" />
                  {/* <img
                    src={cart?.itemImage}
                    alt=""
                    className="w-40"
                    style={{
                      border: "1px solid orange",
                    }}
                  /> */}
                </div>
                <div className="divider lg:divider-horizontal"></div>
                <div>
                  <p className="font-semibold text-2xl mb-4">
                    {cart?.itemName}
                  </p>
                  <p className="mb-5">
                    {t("Note")} :{" "}
                    {t("Actual Product may vary slightly from this image")}
                  </p>
                  <div className="flex mb-8 font-semibold justify-start gap-5 text-lg md:text-[16px] items-center">
                    <p className="line-through">
                      {t("M.R.P")} : ₹ {cart?.itemPrice}
                    </p>
                    <p>
                      {t("Price")} :{" "}
                      <span className="font-bold">
                        {" "}
                        ₹ {cart?.itemDiscPrice}
                      </span>
                    </p>
                    <p className="text-orange-500">
                      {(
                        ((cart?.itemPrice - cart?.itemDiscPrice) /
                          cart?.itemPrice) *
                        100
                      ).toFixed(2)}
                      % off
                    </p>
                  </div>
                  <div className="flex items-center gap-8">
                    <p>{t("Quantity")} : </p>

                    <div className="flex justify-center">
                      <button
                        className="btn btn-warning btn-sm rounded-l-lg rounded-none btn-square w-12"
                        onClick={() => handleQuantityDecrease(cart)}
                      >
                        <FaMinus />
                      </button>
                      <div
                        className="border-black font-semibold text-center w-12"
                        style={{
                          border: "1px solid orange",
                        }}
                      >
                        {cart?.quantity || 1}
                      </div>
                      <button
                        className="btn btn-warning btn-sm rounded-r-lg rounded-none btn-square w-12"
                        onClick={() => handleQuantityIncrease(cart)}
                      >
                        <FaPlus />
                      </button>
                    </div>
                    <div className="text-right">
                      <button
                        onClick={() => handleCartDelete(cart?._id)}
                        className="btn btn-warning btn-circle btn-sm bg-red-600 hover:bg-red-400"
                      >
                        <FaTrash className="text-white"></FaTrash>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full">
          <div
            className="shadow-xl mx-6 p-4"
            style={{
              border: "1px solid orange",
            }}
          >
            <h2>{t("PRICE DETAILS")}</h2>
            <div className="divider"></div>
            <div>
              <div className="flex items-center mb-3 justify-between">
                <p>{t("Total Items")}</p>
                <p>{carts?.length}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>{t("Total Items Quantity")}</p>
                <p>{totalItemsQuantity}</p>
              </div>
              <div className="divider"></div>
              <div className="flex font-bold items-center justify-between">
                <p>{t("Total Amount")}</p>
                <p>₹ {totalPrice?.toFixed(2)}</p>
              </div>
            </div>
            <div>
              <Link
                state={carts}
                to={`/placeOrder?totalItems=${
                  carts?.length
                }&totalQuantity=${totalItemsQuantity}&totalPrice=${totalPrice?.toFixed(
                  2
                )}`}
              >
                <button className="btn btn-warning btn-block btn-sm text-white mt-4 bg-orange-500">
                  {" "}
                  ₹ {t("Check Out")}
                </button>
              </Link>
            </div>
            <div>
              <p className="mt-5">{t("Delivery Charges Extra")}</p>
              <hr className="bg-black mt-2" />
              <div className="flex mt-3 items-center mb-3 justify-between">
                <p>{t("Online")}</p>
                <p className="text-orange-500 font-bold">FREE</p>
              </div>
              <div className="flex items-center justify-between">
                <p>{t("COD")}</p>
                <p>₹ 50</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
