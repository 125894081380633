import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import tEn from "./locales/en.json";
import tBn from "./locales/bn.json";
import tHi from "./locales/hi.json";
import tMr from "./locales/mr.json";
import tOr from "./locales/or.json";
import tGu from "./locales/gu.json";
import tTa from "./locales/ta.json";
import tTe from "./locales/te.json";
import tKn from "./locales/kn.json";
import tMl from "./locales/ml.json";

const defaultLanguage = localStorage.getItem("lang");

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: tEn,
      },
      bn: {
        translation: tBn,
      },
      hi: {
        translation: tHi,
      },
      mr: {
        translation: tMr,
      },
      or: {
        translation: tOr,
      },
      gu: {
        translation: tGu,
      },
      ta: {
        translation: tTa,
      },
      te: {
        translation: tTe,
      },
      kn: {
        translation: tKn,
      },
      ml: {
        translation: tMl,
      },
    },
    lng: defaultLanguage ? defaultLanguage : "en", //default language

    fallbackLng: defaultLanguage ? defaultLanguage : "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
