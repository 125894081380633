import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";

import { useLocation } from "react-router-dom";
import useScrollTop from "../Hooks/useScrollTop";
import Loading from "../Pages/Shared/Loading";
import { auth } from "../firebase.init";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const OrderDetails = () => {
  const { t } = useTranslation();
  const { pathName } = useLocation();
  useScrollTop(pathName);
  const [user] = useAuthState(auth);
  // let email;
  // if(user){
  //   email = user?.email;
  // }
  // console.log(email);
  console.log(user?.phoneNumber);
  // console.log(user.displayName);
  const url = `${process.env.REACT_APP_BACKEND_URL}/orders/get-orders?userMobileNumber=${user?.phoneNumber}`;

  const { data: orders, isLoading } = useQuery({
    queryKey: ["get-orders", user?.phoneNumber],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data;
    },
  });
  console.log(orders);

  if (orders?.length === 0) {
    return (
      <div className="min-h-screen text-center">
        <p className="text-xl text-center mt-32 text-orange-500 font-semibold">
          {t("There are no orders yet")}
        </p>
      </div>
    );
  }

  // function truncateText(text, maxLength) {
  //   if (text?.length <= maxLength) {
  //     return text;
  //   } else {
  //     return text?.slice(0, maxLength) + '...'; // Add an ellipsis (...) to indicate truncation
  //   }
  // }

  return (
    <div className="pb-8 min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Orders</title>
      </Helmet>
      <div className="p-8  bg-orange-500">
        <h1 className="text-center font-semibold text-3xl text-white">
          {t("My Orders")}
        </h1>
      </div>
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <div className="overflow-x-auto px-4 mt-8 lg:flex justify-center">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th className="dark:bg-secondary">#</th>
                <th className="dark:bg-secondary">{t("Item Name")}</th>
                <th className="dark:bg-secondary">{t("Total Amount")}</th>
                <th className="dark:bg-secondary">{t("Customer Name")}</th>
                <th className="dark:bg-secondary">{t("Billing User Name")}</th>
                <th className="dark:bg-secondary">
                  {t("Ordered Date & Time")}
                </th>
                <th className="dark:bg-secondary">{t("Transaction Id")}</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order, index) => (
                <tr key={index}>
                  <td className="dark:bg-secondary">{index + 1}</td>
                  {/* <td className="dark:bg-secondary" style={{ whiteSpace: 'pre-line' }}>{truncateText(order.itemName, 100)}</td> */}
                  <td
                    className="dark:bg-secondary"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {order.items
                      .map((item) => `${item.itemName} (${item.quantity})`)
                      .join(", ")}
                  </td>
                  <td className="dark:bg-secondary">  ₹{order.amount + (order.deliveryFee ? parseFloat(order.deliveryFee) : 0)}</td>
                  <td className="dark:bg-secondary">{order.userName}</td>
                  <td className="dark:bg-secondary">{order.pickupPerson}</td>
                  <td className="dark:bg-secondary">
                    {moment(order.orderDate).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td className="dark:bg-secondary">
                    {order.transactionId ? order.transactionId : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
