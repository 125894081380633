import React, { useState } from "react";
import "./SubscriptionModal.css";
import { RxCross1 } from "react-icons/rx";
import { HiCheckCircle } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import PlanSwitch from "./PlanSwitch";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const SubscriptionModal = ({
  showSubscriptionModal,
  setShowSubscriptionModal,
}) => {
  const [goldPlan, setGoldPlan] = useState("gold_y");
  const [platinumPlan, setPlatinumPlan] = useState("plat_y");
  const userId = localStorage.getItem("user-id");
  const profileDetailsUrl = `${process.env.REACT_APP_ENDPOINT}/user/profile/${userId}`;
  const {
    data: profileDetails,

   
 
  } = useQuery([`profileDetails`, userId], () =>
    axios.get(profileDetailsUrl).then((data) => {
      return data.data;
    })
  );
  // console.log(profileDetails);
  return (
    <>
      {showSubscriptionModal ? (
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 backdrop-blur-sm font-[inter]">
          <div className="relative top-[180px] sm:top-0 rounded-lg bg-white dark:bg-secondary min-h-screen sm:min-h-0 sm:h-[520px] w-64 xs:w-[380px] sm:w-[500px] pt-4 shadow-md">
            <div className="flex border-b-2 px-6 pb-3">
              <div className="flex flex-col">
                <p className="text-black dark:text-white font-semibold">
                  Subscription Plans
                </p>
                <span className="text-sm text-[#7C7C7C] dark:text-[#c5c4c4]">
                  You’re currently on a Free Plan
                </span>
              </div>
              <button
                className="bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center ml-auto"
                onClick={() => setShowSubscriptionModal(false)}
              >
                <RxCross1 className="text-white dark:text-black" />
              </button>
            </div>
            <div className="flex flex-col sm:flex-row pt-6 sm:h-[410px]">
              <div className="mx-8 w-[200px] text-black flex flex-col justify-between">
                <div className="space-y-3">
                  <p className="h-[22px] w-[52px] rounded text-sm font-semibold right-32 text-center text-white gold-plan">
                    GOLD
                  </p>
                  <p className="font-bold text-[32px] text-black dark:text-white">
                    {goldPlan === "gold_m" ? "$99" : "$799"}
                    <span className="font-normal text-xl">
                      {goldPlan === "gold_m" ? "/month" : "/year"}
                    </span>
                  </p>

                  <PlanSwitch plan={goldPlan} setPlan={setGoldPlan} />
                  <ul className="flex flex-col gap-4">
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        Free Categories
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        Gold Categories
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <AiFillCloseCircle className="text-[#E03600] h-[13px] w-[13px]" />
                      <span className="text-[#767676]">
                        Platinum Categories
                      </span>
                    </li>
                  </ul>
                </div>
                <form
                  action={`${process.env.REACT_APP_ENDPOINT}/sub/create-checkout-session`}
                  method="POST"
                >
                  <button
                    type="submit"
                    className="h-[37px] w-[152px] mx-auto rounded-lg text-white font-bold gold-plan mt-2 sm:mt-0"
                  >
                    Buy Now
                  </button>
                  <input type="hidden" name="lookup_key" value={goldPlan} />
                  <input
                    type="hidden"
                    name="name"
                    value={profileDetails?.user?.name}
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={profileDetails?.user?.email}
                  />
                  <input
                    type="hidden"
                    name="phone"
                    value={profileDetails?.user?.phone}
                  />
                </form>
              </div>
              <div className="divider-vertical sm:divider sm:divider-horizontal bg-[#E9E9E9] h-[2px] sm:w-[2px]" />
              <div className="mx-8 w-[200px] text-black flex flex-col justify-between">
                <div className="space-y-3">
                  <p className="h-[22px] w-[85px] rounded text-sm font-semibold right-32 text-center text-white platinum-plan">
                    PLATINUM
                  </p>
                  <p className="font-bold text-[32px] text-black dark:text-white">
                    {platinumPlan === "plat_m" ? "$199" : "$999"}
                    <span className="font-normal text-xl">
                      {platinumPlan === "plat_m" ? "/month" : "/year"}
                    </span>
                  </p>
                  <PlanSwitch plan={platinumPlan} setPlan={setPlatinumPlan} />
                  <ul className="flex flex-col gap-4">
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        Free Categories
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        Gold Categories
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        Platinum Categories
                      </span>
                    </li>
                  </ul>
                </div>
                <form
                  action={`${process.env.REACT_APP_ENDPOINT}/sub/create-checkout-session`}
                  method="POST"
                >
                  <button
                    type="submit"
                    className="h-[37px] w-[152px] mx-auto rounded-lg text-white font-bold platinum-plan mt-2 sm:mt-0"
                  >
                    Buy Now
                  </button>
                  <input type="hidden" name="lookup_key" value={platinumPlan} />
                  <input
                    type="hidden"
                    name="name"
                    value={profileDetails?.user?.name}
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={profileDetails?.user?.email}
                  />
                  <input
                    type="hidden"
                    name="phone"
                    value={profileDetails?.user?.phone}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionModal;
