import { useState } from "react";
import AddProfileModal from "../Modals/AddProfileModal";
import axios from "axios";
import Loader from "../Shared/Loader";
import { useEffect } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import AddAddressModal from "../Modals/AddAddressModal";
import UpdateAddressModal from "../Modals/UpdateAddressModal";
import Swal from "sweetalert2";
import useProfile from "../../Hooks/useProfile";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Profile = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [addressIdToUpdate, setAddressIdToUpdate] = useState(null);
  const [ profileData, isLoading, refetch ] = useProfile();

  useEffect(() => {
    if (profileData) {
      setIsOpen(false);
    }
  }, [profileData]);

  function openModal() {
    setIsOpen(true);
  }
  function openUpdateModal(addressId) {
    setIsUpdateModalOpen(true);
    setAddressIdToUpdate(addressId);
  }
  function openAddressModal() {
    setIsAddressModalOpen(true);
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Delete Address?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#db5d14",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )

        axios
          .delete(
            `${process.env.REACT_APP_BACKEND_URL}/users/deleteAddress/${localStorage.getItem("user-id")}/${id}`
          )
          .then((res) => {
            console.log(res.data);
            refetch();
          });
      }
    });
  };

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Bharat Calendar Bhakthi Shop | Profile</title>
      </Helmet>
      <div className="p-8 bg-orange-500">
        <h1 className="text-center font-semibold text-white text-3xl">
          {t('Personal Information')}
        </h1>
      </div>
      <div
        className="xl:w-1/2 lg:w-8/12 sm:w-9/12 w-full  mx-auto p-5 shadow-md rounded-md mt-5"
        style={{ border: "2px solid orange" }}
      >
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="sm:px-4 px-1 space-y-4">
            <div className="flex justify-start gap-2">
              <label htmlFor="name" className="font-semibold">
                {t("Name")}:
              </label>
              {profileData?.name}
            </div>
            <div className="flex gap-2">
              <label htmlFor="mobileNumber" className="font-semibold">
                {t('Mobile Number')}:
              </label>
              {profileData?.mobileNumber}
            </div>
            <div className="flex justify-center md:justify-start flex-col gap-2">
              <label htmlFor="address" className="font-semibold">
                {t('Customer Address')}:
              </label>
              {profileData?.address?.map((addr, index) => (
                <div
                  key={index}
                  className="flex text-sm mb-2 flex-row justify-between gap-2 rounded-lg card p-2"
                  style={{
                    border: "1px solid orange",
                  }}
                >
                  <div>
                    <p className="font-semibold underline">
                      {t('Address')}: {index + 1}
                    </p>
                    {addr?.deliveryName || ""} <br />
                    {addr?.deliveryNumber || ""}
                    <br />
                    {addr?.houseNumber || ""}, {addr?.roadName || ""},{" "}
                    {addr?.cityName || ""},{addr?.districtName || ""},{" "}
                    {addr?.stateName || ""}
                  </div>
                  <div className="flex justify-center gap-2">
                    <button
                      className="btn btn-sm btn-success btn-circle hover:bg-green-700"
                      onClick={() => openUpdateModal(addr._id)}
                    >
                      <FaEdit className="text-white"></FaEdit>
                    </button>
                    <button
                      className="btn btn-sm btn-circle bg-red-500 hover:bg-red-700"
                      onClick={() => handleDelete(addr._id)}
                    >
                      <FaTrash className="text-white"></FaTrash>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {profileData ? (
                <button
                  onClick={() => openAddressModal()}
                  className="btn btn-warning btn-sm bg-orange-500 text-white capitalize"
                >
                  <FaPlus className="text-xs"></FaPlus>{t('Add Address')}
                </button>
              ) : (
                <button
                  onClick={() => openModal()}
                  className="btn btn-warning btn-sm bg-orange-500 text-white capitalize"
                >
                  Add Information
                </button>
              )}

              <AddProfileModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              ></AddProfileModal>
              <UpdateAddressModal
                isOpen={isUpdateModalOpen}
                setIsOpen={setIsUpdateModalOpen}
                addressId={addressIdToUpdate}
              ></UpdateAddressModal>
              <AddAddressModal
                isOpen={isAddressModalOpen}
                setIsOpen={setIsAddressModalOpen}
              ></AddAddressModal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
