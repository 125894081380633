import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../Shared/Loading";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ItemCard from "./ItemCard";
import AccessItemDiv from "../Shared/AccessItemDiv";

const SubcategoryItems = () => {
  const [accessItemDiv, setAccessItemDiv] = useState(false);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // const { category } = useParams();
  const { category, subcategory } = useLocation().state;
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  const url = `${process.env.REACT_APP_ENDPOINT}/collection/${subcategory?._id}?lat=${coordinates?.lat}&lng=${coordinates?.lng}`;

  const { data: items, isLoading } = useQuery([`items`, url], () =>
    axios.get(url).then((data) => {
      return data.data.items;
    })
  );
  if (isLoading) {
    return <Loading />;
  }
  if (accessItemDiv) {
    return <AccessItemDiv />;
  }
  return (
    <div className="App min-h-screen">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        Home / {Capitalize(category?.collection?.name)} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {subcategory?.name}
        </span>
      </p>
      {items?.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-5">
          {items?.map((item, index) => (
            <ItemCard
              key={index}
              item={item}
              setAccessItemDiv={setAccessItemDiv}
            />
          ))}
        </div>
      ) : (
        <p className="text-center font-[inter] text-xl">No Item is Available</p>
      )}
    </div>
  );
};

export default SubcategoryItems;
