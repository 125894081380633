import React, { useEffect } from "react";
import { useRef } from "react";

const ArticleAds = () => {

  // useEffect(() => {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  const adsbygoogleRef = useRef(false);

  useEffect(() => {
    // Check if the useEffect has already been called
    if (!adsbygoogleRef.current) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      // Set the ref to true to prevent further executions
      adsbygoogleRef.current = true;
    }
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-7950328943419082"
        data-ad-slot="9717293710"
        data-adtest="on"
      ></ins>
    </div>
  );
};

export default ArticleAds;
